import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCanals, joinCanal } from '../../features/canals/canalSlice';
import CanalCard from '../../components/canal/CanalCard';
import CreateCanalPopup from '../../components/canal/createCanalPopup';
import JoinCanalPopup from '../../components/canal/joinCanalPopup';
import LoadingPage from '../../components/general/LoadingPage'; // Importez votre composant de chargement ici
import iconadd from '../../assets/icon-add.svg';
import iconhouse from '../../assets/icon-house.svg';
import iconhouseBlack from '../../assets/icon-house-black.svg';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { addCanal } from '../../features/canals/canalSlice';
import CitySelectByCp from '../../components/general/CitySelect';
import logocolor from '../../assets/transparent-without-circle.png';
import { Tooltip } from 'react-tooltip';
import helpOutline from '../../assets/help-outline.svg';
import { useTranslation } from 'react-i18next';
import QuickSearchComponent from '../../components/animals/QuickSearch';
import { fetchAnimals } from '../../features/animals/animalSlice';
import Sparkles from "../../assets/sparkles-outline-gold.svg";
import { Helmet } from 'react-helmet';
import { updateLicenceStatus } from '../../features/licences/licenceSlice';
import ResendVerificationEmail from '../../components/user/SendVerificationEmail';
import MapComponent from '../Maps/initialMap.jsx';
import DynamicMap from '../Maps/standardMap.js';
import { setIsAssociation } from '../../features/user/userSlice.js';
import ProgressBar from '../../components/general/ProgressBar.jsx';
import { fetchAnimalsCountByCanals } from '../../features/licences/licenceSlice';


const ProfileUser = () => {
    const { t } = useTranslation();
    const { uid, isAuthenticated, name, surname, isMairie, isAssociation, licenceNumber, mairieName, associationName, isBeta, productType, licenceStatus, emailWasVerified, userType } = useSelector((state) => state.auth);
    const { data: canals, status, error } = useSelector((state) => state.canals);
    const { licence } = useSelector((state) => state.licences);
    const { data: animals } = useSelector((state) => state.animals)
    const publishedAnimals = animals.filter((animal) => animal.published === true);
    const [canalsIds, setCanalsIds] = useState('');
    const [canalId, setCanalId] = useState('');
    const [joinCanalAlias, setJoinCanalAlias] = useState('');
    const [nameCanal, setName] = useState('');
    const [canalAlias, setAlias] = useState('');
    const [email, setEmail] = useState('');
    const [city, setCity] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showCreateCanalPopup, setShowCreateCanalPopup] = useState(false);
    const [showCreateCanalAssociationPopup, setShowCreateCanalAssociationPopup] = useState(false);
    const [isCanalAssociation, setIsCanalAssociation] = useState(false);
    const [showJoinCanalPopup, setShowJoinCanalPopup] = useState(false);
    const [animationClass, setAnimationClass] = useState('');
    const [modalQuickSearchOpen, setModalQuickSearchOpen] = useState(false);
    const [codeCommune, setCodeCommune] = useState('');
    const [nomCommune, setNomCommune] = useState('');
    const [creatorCanal, setCreatorCanal] = useState([]);
    const [animalsCount, setAnimalsCount] = useState(0);
    // const [showCreateCanalPopup, setShowCreateCanalPopup] = useState(false);
    const maximumOfAnimals = productType === 1
    ? 30
    : productType === 2 || productType === 4
    ? 100
    : productType === 3 || productType === 5
    ? 1000
    : isBeta ? 1000
    : 30; // valeur par défaut au cas où le productType ne correspond à aucun

    // console.log('emailWasVerified ? ', emailWasVerified)
    const openModalQuickSearch = () => {
        setModalQuickSearchOpen(true)
        // setCanalsIds('')
    };
    const closeModalQuickSearch = () => {
        setModalQuickSearchOpen(false);
    }
    const handleAliasChange = (e) => {
        let input = e.target.value;

        // Supprimez les espaces et autres caractères non désirés
        // Exemple : Remplacement des espaces par des tirets ou suppression des espaces
        input = input.replace(/\s+/g, '');

        // Mettez à jour l'état avec l'alias normalisé
        setAlias(input);
    };


    const handleFormSubmit = (e, data, isCanalAssociation, cid) => {
        e.preventDefault(); // Empêche le rechargement de la page
        handleConfirmJoinCanal(e, data, isCanalAssociation, cid); // Appelle la fonction pour rejoindre le canal
    };

    const handleConfirmJoinCanal = async (e, data, isCanalAssociation, cid) => {
        e.preventDefault();
        if (!data) {
            alert(`${t('modal.pleaseAddAnAliasToThisCanal')}`); // Utilisez une alerte ou une notification appropriée ici
        } else {
            const resultAction = await dispatch(joinCanal({ userId: uid, codeCommune: data, isCanalAssociation: isCanalAssociation, cid: cid }));  // Here is the change
            if (joinCanal.fulfilled.match(resultAction)) {
                Swal.fire({
                    icon: 'success',
                    title: `${t('modal.canalJoined')}`,
                    text: `${t('modal.thisChannelIsNowAvailable')}`,
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK',
                    showClass: {
                        popup: 'animate__animated animate__fadeIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOut'
                    }
                })
                setCanalId('');
            } else {
                if (joinCanal.rejected.match(resultAction)) {
                    // Si une erreur personnalisée a été rejetée avec `rejectWithValue`
                    Swal.fire({
                        icon: 'error',
                        title: `${t('modal.error')}`,
                        text: `${resultAction.payload} `,
                        confirmButtonColor: '#2F4F4F',
                        confirmButtonText: 'OK',
                        showClass: {
                            popup: 'animate__animated animate__fadeIn'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOut'
                        }
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: `${t('modal.error')}`,
                        text: `${t('modal.error')} ${resultAction.error.message} `,
                        confirmButtonColor: '#2F4F4F',
                        confirmButtonText: 'OK',
                        showClass: {
                            popup: 'animate__animated animate__fadeIn'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOut'
                        }
                    })
                }
            }
            handleCloseJoinCanalPopup();
        }
    };


    const handleConfirmCreationCanal = (data) => {
        const formData = {
            codeCommune: data.codeCommune,
            nomCommune: data.nomCommune,
            name: data.nameCanal,
            email: data.email,
            city: data.nomCommune,
            postalCode: data.postalCode,
            phoneNumber: data.phoneNumber,
            id: canalId,
            adminId: uid,
            isCanalAssociation : isCanalAssociation
        };

        // console.log(formData)

        if (!formData.nomCommune || !formData.email || !formData.city || !formData.postalCode || !formData.phoneNumber) {
            alert(`${t('modal.pleaseCheckFields')}`); // Utilisez une alerte ou une notification appropriée ici
        } else {
            // console.log(formData)
            // return;
            handleSubmit(formData);
            handleCloseCanalPopup();
        }
    };

    const handleShowCanalPopup = () => {

        setAnimationClass('popup-entering');
        setShowCreateCanalPopup(true);
        if(isAssociation && !isMairie){
            setIsCanalAssociation(true);
        } else {
            setIsCanalAssociation(false);
        }
        
    };

    const handleShowCanalAssociationPopup = () => {
        setAnimationClass('popup-entering');
        setShowCreateCanalAssociationPopup(true);;
    }

    const handleShowJoinCanalPopup = () => {

        setAnimationClass('popup-entering');
        setShowJoinCanalPopup(true);;
        setTimeout(() => {
            setAnimationClass('');
        }, 500);
    };


    const handleCloseCanalPopup = () => {

        setAnimationClass('popup-exiting');
        setShowCreateCanalPopup(false);
        setAnimationClass(null);
    };

    const handleCloseJoinCanalPopup = () => {

        setAnimationClass('popup-exiting');
        setShowJoinCanalPopup(false);
        setAnimationClass(null);
    };

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchCanals(uid));
        }
    }, [dispatch, uid, isAuthenticated, joinCanal]);

    // Utilisation d'effet pour mettre à jour canalsIds quand canals change
    useEffect(() => {
        if (canals) {
            // Créer un nouveau tableau avec seulement les IDs des canals
            const extractedIds = canals.map(canal => canal?.id); // Remplacez 'id' par la clé appropriée pour l'ID du canal
            setCanalsIds(extractedIds);
        }
    }, [canals]); // Dépendance à canals pour que l'effet s'exécute quand canals change

    useEffect(() => {
        if (canals) {
            dispatch(fetchAnimals(canalsIds))

        }
        if (licenceNumber) {
            dispatch(updateLicenceStatus({ licenceId: licenceNumber, canalsId: canalsIds, userId: uid }))
        }
    }, [dispatch, canalsIds, canals, licenceNumber]);


    useEffect(() => {
        if (isAuthenticated && uid) {
            dispatch(fetchCanals(uid));
            if (uid) {
                let creatorCanals = [];

                for (let canal of canals) {
                    if (Array.isArray(canal.role)) {
                        for (let role of canal.role) {
                            if (role.isAdmin === true && role.uid === uid && canal.adminId === uid) {
                                creatorCanals.push(canal);
                                break;
                            }
                        }
                    }
                }

                setCreatorCanal(creatorCanals);
            }
            else {
                setCreatorCanal(null);
            }
        }
    }, [dispatch, uid, isAuthenticated]);
    
    useEffect(() => {
        const updateCanalsIdsAndFetchAnimals = async () => {
            let extractedIds = [];
            if (canals) {

                
                if (userType === 'mairie') {
                    extractedIds = canals.map(canal => canal?.id); // Remplacez 'id' par la clé appropriée pour l'ID du canal
                } else if (userType === "association") {
                    extractedIds = creatorCanal.map(canal => canal?.id); // Remplacez 'id' par la clé appropriée pour l'ID du canal
                }
                setCanalsIds(extractedIds);
            }
    
            if (extractedIds.length > 0 && uid) {
                try {
                    const count = await fetchAnimalsCountByCanals(extractedIds, uid);
                    setAnimalsCount(count);
                } catch (error) {
                    console.error('Error fetching animals count:', error);
                }
            }
        };
    
        updateCanalsIdsAndFetchAnimals();
    }, [dispatch, uid, canals, isAuthenticated]);



    if (status === 'loading') {
        return <LoadingPage isLoading={true} />;
    }





    const handleSubmit = (form) => {
        const data = {
            codeCommune: form.codeCommune,
            nomCommune: form.nomCommune,
            name: form.name,
            email: form.email,
            city: form.nomCommune,
            postalCode: form.postalCode,
            phoneNumber: form.phoneNumber,
            adminId: uid,
            role: [{ uid: uid, isAdmin: true }],
            visibilityListEnabled: false,
            visibilitySectorsEnabled: false,
            canalReachable: false,
            isCanalAssociation : form.isCanalAssociation

        };
        dispatch(addCanal({ userId: data.adminId, canalData: data, codeCommune: data.codeCommune }))
            .then((resultAction) => {

                if (addCanal.fulfilled.match(resultAction)) {
                    Swal.fire({
                        icon: 'success',
                        title: `${t('modal.canalCreated')}`,
                        text: `${t('modal.thisChannelIsNowAvailable')}`,
                        confirmButtonColor: '#2F4F4F',
                        confirmButtonText: 'OK',
                        showClass: {
                            popup: 'animate__animated animate__fadeIn'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOut'
                        }
                    })
                    navigate('/');

                }

                else if (addCanal.rejected.match(resultAction)) {
                    let errorMessage = resultAction.payload ? resultAction.payload : "Une erreur s'est produite.";
                    Swal.fire({
                        icon: 'error',
                        title: `${t('modal.error')}`,
                        text: `${t('modal.canalCreatedError')} ${errorMessage}`,
                        confirmButtonColor: '#2F4F4F',
                        confirmButtonText: 'OK',
                        showClass: {
                            popup: 'animate__animated animate__fadeIn'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOut'
                        }
                    })

                }

            })
            .catch((error) => {
                console.error('Error adding canal: ', error);
                Swal.fire({
                    icon: 'error',
                    title: `${t('modal.canalCreatedError')}`,
                    text: `${t('modal.canalCreatedError')} ${error}`,
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK',
                    showClass: {
                        popup: 'animate__animated animate__fadeIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOut'
                    }
                })
            });
    };

    const QuickSearch = ({ onClose, children }) => {
        return (
            <div className="modalqs-backdrop">
                <div className="modalqs-content">
                    <button onClick={onClose}>{t('close')}</button>
                    {children}

                </div>
            </div>
        );
    };




    return (
        <div className='kappze-page user-page'>

            <Helmet>
                <meta name="description" content="Kappze est le premier outil dans la gestion des chats errants à destination des mairies et associations. Les chats libres, vraiment libres." />
            </Helmet>
            <LoadingPage isLoading={false} />


            <>


                {!emailWasVerified && (
                    <ResendVerificationEmail />
                )}

                {canals.length ?

                
                    (<div style={{ padding: '2rem' }}>

                        <div className="header-listing" style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', columnGap: '1rem', rowGap: '1rem' }}>
                            <div>
                            <h2 className=''>{t('hello')} {surname} {name} </h2>
                            {(maximumOfAnimals && (isMairie || isAssociation)) && (
                                <div style={{maxWidth: '350px', marginTop: '25px', display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '1rem'}}>
                            <ProgressBar numberOfAnimals={animalsCount} maximumOfAnimals={maximumOfAnimals} />
                            {/* <a href="/checkout" >Obtenir plus</a> */}
                            <NavLink to={`/checkout/`} style={{width:'100%', textDecoration: 'underline', fontSize: '12px'}}>
                                        <span>Obtenir plus</span>
                                    </NavLink>
                                    <img style={{ width: '14px', height: '14px', borderRadius: '50%', border: '1px solid black', background: '#122', marginBottom: '10px' }} src={helpOutline} alt="Icon Compass" className="button-icon" data-tooltip-id="my-tooltip-animals-available" />
                                    <Tooltip id="my-tooltip-animals-available" style={{ backgroundColor: "#122", color: "#fff", padding: '1rem', maxWidth: '248px' }}>
                                            <div style={{ backgroundColor: '#122' }}>
                                                <h3>Nombre d'animaux</h3>
                                                <p>Nombre d'animaux inscrits dans l'ensemble des canaux dont vous êtes le créateur.</p>

                                                <ul>
                                                    <li>Vos animaux ajoutés dans des canaux auxquels vous n'êtes pas le créateur ne sont pas comptabilisés.</li>

                                                </ul>
                                            </div>
                                        </Tooltip>
                            </div>
                        )}
                        </div>
                        
                            <div className=' home-page_content-buttons'>

                            



                            
                                {(isMairie) && (
                                    <><button onClick={handleShowCanalPopup} className='button-add-canal button-general-link'>
                                        <span>{t('createChannel')} </span>
                                        <img style={{ width: '20px', marginLeft: '5px' }} src={iconhouse} alt="Icon Add" className="button-icon" />
                                        <img style={{ width: '10px', margin: '0px' }} src={iconadd} alt="Icon Add" className="button-icon" />
                                    </button>
                                        <img style={{ width: '14px', height: '14px', borderRadius: '50%', border: '1px solid black', background: '#122', marginBottom: '10px' }} src={helpOutline} alt="Icon Compass" className="button-icon" data-tooltip-id="my-tooltip-create-canal" />


                                        <Tooltip id="my-tooltip-create-canal" style={{ backgroundColor: "#122", color: "#fff", padding: '1rem', maxWidth: '248px' }}>
                                            <div style={{ backgroundColor: '#122' }}>
                                                <h3>{t('alias.homePage.title')}</h3>
                                                <p>{t('alias.homePage.text1')}.</p>

                                                <ul>
                                                    <li>{t('alias.homePage.list1')}</li>

                                                </ul>
                                            </div>
                                        </Tooltip></>)}

                                        
                                {!isMairie && (
                                    <>
                                    <button style={{ border: 'solid 1px white', borderBox: 'box-sizing' }} onClick={handleShowJoinCanalPopup} className='button-general-link'>Rejoindre un canal</button>
                                            <img style={{ width: '14px', height: '14px', borderRadius: '50%', border: '1px solid black', background: '#122', marginBottom: '10px' }} src={helpOutline} alt="Icon Compass" className="button-icon" data-tooltip-id="my-tooltip-join-canal2" />


                                            <Tooltip id="my-tooltip-join-canal2" style={{ backgroundColor: "#122", color: "#fff", padding: '1rem', maxWidth: '248px' }}>
                                                <div style={{ backgroundColor: '#122' }}>
                                                    <h3>Canal</h3>
                                                    <p>Un canal est l'équivalent d'une commune.</p>
                                                    <ul>
                                                        <li>Il vous faudra chercher votre commune par le code postal.</li>
                                                    </ul>
                                                </div>
                                            </Tooltip>
                                        {isAssociation && (
                                            <><button onClick={handleShowCanalPopup} className='button-add-canal button-general-link'>
                                        <span>{t('createCustomCanal')} </span>
                                        <img style={{ width: '20px', marginLeft: '5px' }} src={iconhouse} alt="Icon Add" className="button-icon" />
                                        <img style={{ width: '10px', margin: '0px' }} src={iconadd} alt="Icon Add" className="button-icon" />
                                    </button>
                                        <img style={{ width: '14px', height: '14px', borderRadius: '50%', border: '1px solid black', background: '#122', marginBottom: '10px' }} src={helpOutline} alt="Icon Compass" className="button-icon" data-tooltip-id="my-tooltip-create-canal-association" />


                                        <Tooltip id="my-tooltip-create-canal-association" style={{ backgroundColor: "#122", color: "#fff", padding: '1rem', maxWidth: '248px' }}>
                                            <div style={{ backgroundColor: '#122' }}>
                                                <h3>{t('alias.homePage.title')}</h3>
                                                <p>{t('alias.homePage.associationCanal')}</p>

                                                <ul>
                                                    <li>{t('alias.homePage.associationCanal2')}</li>

                                                </ul>
                                            </div>
                                        </Tooltip></>
                                        )}
                                    </>
                                )}
                            </div>


                        </div>
                        <div className="listing-section" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '4rem' }}>
                            <p><b>21/08/2024</b> - Les associations peuvent désormais bénéficier de Kappze ! </p>

                            {/* <a href="https://kappze.com/patch-1-2-kappze/" target="_blank"
                                rel="noreferrer" style={{ color: '#ddd', marginRight: '16px', textDecoration: 'underline' }} className="header-elt">
                                Patch 1.2 - Kappze
                            </a> */}
                        </div>
                        <div>
                        </div>
                        <div className='list-canal'>
                            {
                                canals.map((canal) => (
                                    <CanalCard key={canal?.id} canal={canal} />
                                ))}
                        </div>
                        <NavLink to={`/documentation/`} className='button-general-link'>
                            <button className='how-does-it-works'>{t('howDoesItWork')}</button>
                        </NavLink>

                        {modalQuickSearchOpen && (
                            <QuickSearch onClose={closeModalQuickSearch}> <QuickSearchComponent animals={publishedAnimals} /> </QuickSearch>
                        )}



                    </div>) : (

                        

                        <div style={{ padding: '2rem' }}>

                            <div className="header-listing" style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', columnGap: '1rem', rowGap: '1rem' }}>
                                <h2 className='listing-hello'>Bonjour {surname} {name} </h2>

      
                                <div className=' home-page_content-buttons'>

                                    {(isMairie) && (
                                        <><button onClick={handleShowCanalPopup} className='button-add-canal button-general-link'>
                                            <span>Créer un canal</span>
                                            <img style={{ width: '20px', marginLeft: '5px' }} src={iconhouse} alt="Icon Add" className="button-icon" />
                                            <img style={{ width: '10px', margin: '0px' }} src={iconadd} alt="Icon Add" className="button-icon" />
                                        </button>
                                            <img style={{ width: '14px', height: '14px', borderRadius: '50%', border: '1px solid black', background: '#122', marginBottom: '10px' }} src={helpOutline} alt="Icon Compass" className="button-icon" data-tooltip-id="my-tooltip-create-canal2" />


                                            <Tooltip id="my-tooltip-create-canal2" style={{ backgroundColor: "#122", color: "#fff", padding: '1rem', maxWidth: '248px' }}>
                                                <div style={{ backgroundColor: '#122' }}>
                                                    <h3>Canal</h3>
                                                    <p>Un canal est l'équivalent d'une commune.</p>
                                                </div>
                                            </Tooltip></>)}

                                    {(!isMairie) && (
                                        <>
                                        <button style={{ border: 'solid 1px white', borderBox: 'box-sizing' }} onClick={handleShowJoinCanalPopup} className='button-general-link'>Rejoindre un canal</button>
                                                <img style={{ width: '14px', height: '14px', borderRadius: '50%', border: '1px solid black', background: '#122', marginBottom: '10px' }} src={helpOutline} alt="Icon Compass" className="button-icon" data-tooltip-id="my-tooltip-join-canal2" />


                                                <Tooltip id="my-tooltip-join-canal2" style={{ backgroundColor: "#122", color: "#fff", padding: '1rem', maxWidth: '248px' }}>
                                                    <div style={{ backgroundColor: '#122' }}>
                                                        <h3>Canal</h3>
                                                        <p>Un canal est l'équivalent d'une commune.</p>
                                                        <ul>
                                                            <li>Il vous faudra chercher votre commune par le code postal.</li>
                                                        </ul>
                                                    </div>
                                                </Tooltip>

                                        </>

                                    )}

                                    {(isAssociation) && (
                                        <><button onClick={handleShowCanalPopup} className='button-add-canal button-general-link'>
                                            <span>Créer un canal</span>
                                            <img style={{ width: '20px', marginLeft: '5px' }} src={iconhouse} alt="Icon Add" className="button-icon" />
                                            <img style={{ width: '10px', margin: '0px' }} src={iconadd} alt="Icon Add" className="button-icon" />
                                        </button>
                                            <img style={{ width: '14px', height: '14px', borderRadius: '50%', border: '1px solid black', background: '#122', marginBottom: '10px' }} src={helpOutline} alt="Icon Compass" className="button-icon" data-tooltip-id="my-tooltip-create-canal2" />


                                            <Tooltip id="my-tooltip-create-canal2" style={{ backgroundColor: "#122", color: "#fff", padding: '1rem', maxWidth: '248px' }}>
                                                <div style={{ backgroundColor: '#122' }}>
                                                    <h3>Canal</h3>
                                                    <p>Un canal est l'équivalent d'une commune, ou d'un endroit précis.</p>
                                                </div>
                                            </Tooltip></>)}


                                </div>
                            </div>
                            <div className="listing-section" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '4rem' }}>

           
                                {isMairie ?
                                    (<><h1 className="listing-title" style={{ marginBottom: '1.7rem' }}>Vous n'avez pas encore de canaux. </h1><br />
                                        <div className="listing-tutorial" style={{ display: 'flex', flexDirection: 'column', paddingLeft: '5rem' }}>
                                            <span className="listing-elt" style={{ display: 'flex', alignItems: 'center', columnGap: "0.5rem", justifyContent: 'flex-start', marginLeft: '1rem' }}><p className="listing-number" style={{ background: "#122", width: "28px", height: "28px", borderRadius: "50%", textAlign: 'center', lineHeight: '1.8' }}>1</p><p className='listing-text'>Commencez par créer un canal.</p></span>
                                            <span className="listing-elt" style={{ display: 'flex', alignItems: 'center', columnGap: "0.5rem", justifyContent: 'flex-start', marginLeft: '1rem' }}><p className="listing-number" style={{ background: "#122", width: "28px", height: "28px", borderRadius: "50%", textAlign: 'center', lineHeight: '1.8' }}>2</p><p className='listing-text'>Activez la visibilité publique du canal pour que les utilisateurs puissent vous rejoindre.</p></span>
                                            <span className="listing-elt" style={{ display: 'flex', alignItems: 'center', columnGap: "0.5rem", justifyContent: 'flex-start', marginLeft: '1rem' }}><p className="listing-number" style={{ background: "#122", width: "28px", height: "28px", borderRadius: "50%", textAlign: 'center', lineHeight: '1.8' }}>3</p><p className='listing-text'>Administrez les droits des utilisateurs dans les paramètres du canal créé.</p></span>
                                        </div>


                                    </>) : (<><h1 className="listing-title" style={{ marginBottom: '1.7rem' }}>Vous n'avez pas encore de canaux. </h1><br />
                                        <div className="listing-tutorial" style={{ display: 'flex', flexDirection: 'column', paddingLeft: '5rem' }}>
                                            <span className="listing-elt" style={{ display: 'flex', alignItems: 'center', columnGap: "0.5rem", justifyContent: 'flex-start', marginLeft: '1rem' }}><p className="listing-number" style={{ background: "#122", width: "28px", height: "28px", borderRadius: "50%", textAlign: 'center', lineHeight: '1.8' }}>1</p><p className='listing-text'>Commencez par rejoindre un canal.</p></span>
                                            <span className="listing-elt" style={{ display: 'flex', alignItems: 'center', columnGap: "0.5rem", justifyContent: 'flex-start', marginLeft: '1rem' }}><p className="listing-number" style={{ background: "#122", width: "28px", height: "28px", borderRadius: "50%", textAlign: 'center', lineHeight: '1.8' }}>2</p><p className='listing-text'>Cherchez votre commune via le code postal.</p></span>
                                            <span className="listing-elt" style={{ display: 'flex', alignItems: 'center', columnGap: "0.5rem", justifyContent: 'flex-start', marginLeft: '1rem' }}><p className="listing-number" style={{ background: "#122", width: "28px", height: "28px", borderRadius: "50%", textAlign: 'center', lineHeight: '1.8' }}>3</p><p className='listing-text'>Si il est disponible, rejoindre le canal de votre commune.</p></span>
                                        </div>


                                    </>)}
                            </div>
                        </div>


                    )}

            </>




            {/* {showCreateCanalPopup && (
                <div className={`popup-container ${animationClass}`}>
                    <div className="popup">
                        <span className="popup-header" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <img src={iconhouseBlack} style={{ maxWidth: '50px', marginBottom: 0, paddingBottom: 0, marginTop: '10px' }} />

                            <h2 className='editable-fields-label'>Créer un canal</h2>
                        </span>

                        <form id="form-create" className="popup-singleinput" >

                        <CitySelectByCp setCodeCommune={setCodeCommune} setNomCommune={setNomCommune} />

                            <div className="formCreateElt">
                                <p style={{ color: 'black' }}>Nom du Canal *</p>
                                <input id="formCreate-name" required
                                    value={nameCanal}
                                    onChange={(e) => setName(e.target.value)} placeholder='Ex : COMMUNE DE FONDCOMBES' />
                            </div>
                            <div className="formCreateElt">
                                <p style={{ color: 'black' }}>Alias du canal *</p>
                                <input id="formCreate-name" required
                                    value={canalAlias}
                                    onChange={handleAliasChange} placeholder='Ex: communefondcombes2023' />
                            </div>
                            <div className="formCreateElt">
                                <p style={{ color: 'black' }}>Email du responsable *</p>

                                <input id="formCreate-name" required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)} placeholder='Email' />
                            </div>
                            <div className="formCreateElt">
                                <p style={{ color: 'black' }}>Nom de la ville *</p>

                                <input id="formCreate-name" required
                                    value={nomCommune}
                                    onChange={(e) => setCity(e.target.value)} placeholder='Nom de la ville' />
                            </div>
                            <div className="formCreateElt">
                                <p style={{ color: 'black' }}>Code postal *</p>

                                <input id="formCreate-name" required
                                    value={codeCommune}
                                    onChange={(e) => setPostalCode(e.target.value)} placeholder='Code postal' />
                            </div>
                            <div className="formCreateElt">
                                <p style={{ color: 'black' }}>Numéro de téléphone *</p>

                                <input id="formCreate-name" required
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)} placeholder='Numéro de téléphone' />
                            </div>
                        </form>
                        <div className="popup-btns">
                            <button className="btn-cancel" onClick={handleCloseCanalPopup}>Annuler</button>
                            <button className="btn-validate" onClick={handleConfirmCreationCanal}>Valider</button>
                        </div>
                    </div>
                </div>
            )} */}

            <CreateCanalPopup
                show={showCreateCanalPopup}
                handleClose={handleCloseCanalPopup}
                handleConfirm={handleConfirmCreationCanal}
                animation={animationClass}
                isCanalAssociation={isCanalAssociation}
            />



            <JoinCanalPopup
                show={showJoinCanalPopup}
                handleClose={handleCloseJoinCanalPopup}
                handleConfirm={handleFormSubmit}
                canals={canals}
                animation={animationClass}
            />




            {/* {showJoinCanalPopup && (
                <div className={`popup-container ${animationClass}`}>
                    <div className="popup">
                        <div className="popup-logo">
                            <img src={logocolor} />
                        </div>
                        <h2 className='editable-fields-label'>Rejoindre un canal</h2>
                        <form id="form-create" className="popup-singleinput" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onSubmit={handleFormSubmit}>
                            <input id="citySector-name"
                                value={joinCanalAlias}
                                onChange={(e) => setJoinCanalAlias(e.target.value)} placeholder='Alias du canal' style={{ padding: '10px' }} />
                        </form>
                        <div className="popup-btns">
                            <button className="btn-cancel" onClick={handleCloseJoinCanalPopup}>Annuler</button>
                            <button className="btn-validate" type="submit">Valider</button>
                        </div>
                    </div>
                </div>
            )} */}

        </div>
    );


};



export default ProfileUser;



import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser } from '../../features/user/userSlice.js';
import { NavLink, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import 'animate.css';
import logocat from '../../assets/transparent-without-circle.png';
import iconpaw from '../../assets/icon-paw.svg';
import sparkle from '../../assets/sparkles-outline.svg';
import { getErrorMsg, validateEmail, validatePhoneNumber, validatePassword, validateString, validateStringCommunity } from '../../utils/errorMessages.js';
import { fetchRNAData, fetchSiretData, validateSiret, validateSiretAsso, validateRnaAsso } from '../../features/siret/siretSlice.js';
import { resetSiretState } from '../../features/siret/siretSlice.js';
import { useTranslation } from 'react-i18next';
import logostandard from '../../assets/logos/kappze_logo_circle_noir_vert.png';
import logopremium from '../../assets/logos/kappze_logo_circle_noir_gold.png';
import { Helmet } from 'react-helmet';
import SirenFetcher from '../general/SirenFetcher.jsx';
import PostalCodeFetcher from '../general/PostalCodeFetcher.jsx';
import CitySelectByCp from '../general/CitySelect.jsx';
import { addLicence } from '../../features/licences/licenceSlice.js';

const RegistrationForm = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [phone, setPhone] = useState('');
    const [communityName, setCommunityName] = useState('');
    const [associationName, setAssociationName] = useState('');
    const [associationSiret, setAssociationSiret] = useState('');
    const [associationRna, setAssociationRna] = useState('');
    const [errMail, setErrMail] = useState('');
    const [errName, setErrName] = useState('');
    const [errCommunity, setErrCommunity] = useState('');
    const [errAssociation, setErrAssociation] = useState('');
    const [errSurname, setErrSurname] = useState('');
    const [errPassword, setErrPassword] = useState('');
    const [errPhoneNumber, setErrPhoneNumber] = useState('');
    const [requestInProcess, setRequestInProcess] = useState(null);
    const [err, setErr] = useState('');
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [siretData, setSiretData] = useState(null)

    const [errorMessage, setError] = useState('');
    const { error: errorCode } = useSelector(state => state.auth);
    // // const siretData = useSelector(state => state.siret);

    // console.log(siretData)

    const [userType, setUserType] = useState('visitor'); // 'visitor', 'mairie', 'canal'
    const [isAssociation, setIsAssociation] = useState(false)
    const [newsletterConsent, setNewsletterConsent] = useState(false);
    const [siret, setSiret] = useState('');
    const [errSiret, setErrSiret] = useState();
    const [errSiretAsso, setErrSiretAsso] = useState();
    const [assoIdType, setAssoIdType] = useState('rna'); // 'visitor', 'mairie', 'canal'

    const dispatch = useDispatch();
    const navigate = useNavigate();


    useEffect(() => {
        if (errorCode) {
            setError(getErrorMsg(errorCode));
        } else {
            setError(null);
        }

        return () => {
            setError(null); // réinitialisez l'erreur lorsque le composant se démonte
        };

    }, [errorCode, setError]);


    const handleEmailChange = (event, err) => {
        const errors = validateEmail(event.target.value);
        setErrMail(errors)
        setEmail(event.target.value);

    };

    const handlePasswordChange = (event) => {
        const errors = validatePassword(event.target.value);
        setErrPassword(errors)
        setPassword(event.target.value);
    };

    const handleNameChange = (event) => {
        const errors = validateString(event.target.value)
        setErrName(errors);
        setName(event.target.value);
    };

    const handleCommunityNameChange = (event) => {
        const errors = validateStringCommunity(event.target.value)
        setErrCommunity(errors);
        setCommunityName(event.target.value);
    };

    const handleSiretChange = async (event) => {
        const siretValue = event.target.value;
        // Attendre la validation asynchrone du SIRET
        const errors = await validateSiret(siretValue);
        setErrSiret(errors.siret);
        setSiret(siretValue);
    };



    const handleAssociationNameChange = (event) => {
        const errors = validateStringCommunity(event.target.value)
        setErrAssociation(errors);
        setAssociationName(event.target.value);
    };

    const handleAssociationSiretChange = async (event) => {
        const siretValue = event.target.value;
        const errors = await validateSiretAsso(siretValue);
        setErrSiretAsso(errors.rna);
        // setAssociationSiret(rnaValue);
        setAssociationSiret(siretValue)
    };

    const handleAssociationRnaChange = async (event) => {
        const rnaValue = event.target.value;
        const errors = await validateRnaAsso(rnaValue);
        setErrSiretAsso(errors.rna);
        // setAssociationSiret(rnaValue);
        setAssociationRna(rnaValue)
    };

    const handleSurnameChange = (event) => {
        const errors = validateString(event.target.value)
        setErrSurname(errors);
        setSurname(event.target.value);
    };

    const handlePhoneChange = (event) => {
        const errors = validatePhoneNumber(event.target.value)
        setErrPhoneNumber(errors);
        setPhone(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setRequestInProcess(true);

        // console.log("siret: " , siret)
        // console.log('siret Errors : ', errSiret)
        // Vérification des erreurs avant de procéder à l'envoi
        let siretData = {};
        let organizationData = {};
        let denomination = null;
        let identifiantCanal = null;
        let libelleEtablissement = null;
        let codeCommuneEtablissement = null;
        let codePostalEtablissement = null;

        if (userType === 'mairie') {
            // dispatch(resetSiretState());
            const actionResult = await dispatch(fetchSiretData(siret));


            if (actionResult.type === fetchSiretData.fulfilled.type) {
                const siretResponse = actionResult.payload;
                siretData.denomination = siretResponse.etablissement?.uniteLegale?.denominationUniteLegale || '';
                siretData.identifiantCanal = siretResponse.etablissement?.uniteLegale?.identifiantAssociationUniteLegale;
                siretData.libelleEtablissement = siretResponse.etablissement?.adresseEtablissement?.libelleCommuneEtablissement;
                siretData.codeCommuneEtablissement = siretResponse.etablissement?.adresseEtablissement?.codeCommuneEtablissement;
                siretData.codePostalEtablissement = siretResponse.etablissement?.adresseEtablissement?.codePostalEtablissement;


                // console.log(siretData)

                if (userType === 'mairie' && !(siretData.denomination.includes('VILLE DE') || siretData.denomination.includes('MAIRIE DE') || siretData.denomination.includes('COMMUNE') || siretData.denomination.includes('COLLECTIVITE'))) {
                    setErrSiret("Le SIRET fourni n'appartient pas à une mairie.");
                    setRequestInProcess(false);
                    return;
                } else {
                    setErrSiret(null);
                }

                // console.log(siretData)

            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Erreur',
                    text: `Une erreur s'est produite lors de la mise à jour de l\'ajout du SIRET : ${fetchSiretData.fulfilled.error}`,
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK',
                    showClass: {
                        popup: 'animate__animated animate__fadeIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOut'
                    }
                });
            }
        }


        // if (userType === 'mairie' && isAssociation && (associationRna || associationSiret)) {
        //     // dispatch(resetSiretState());
        //     // const actionResult = await dispatch(fetchRNAData(associationRna));
        //     let actionResult;

        //     if(associationRna) {
        //         actionResult = await dispatch(fetchRNAData(associationRna));
        //     } else {
        //         actionResult = await dispatch(fetchSiretData(associationSiret));
        //     }

        //     if (actionResult.type === fetchRNAData.fulfilled.type) {
        //         const siretResponse = actionResult.payload;

        //         organizationData.denomination = siretResponse.etablissement?.uniteLegale?.denominationUniteLegale || '';
        //         organizationData.identifiantCanal = siretResponse.etablissement?.uniteLegale?.identifiantAssociationUniteLegale || siretResponse.etablissement?.uniteLegale?.economieSocialeSolidaireUniteLegale;
        //         organizationData.libelleEtablissement = siretResponse.etablissement?.adresseEtablissement?.libelleCommuneEtablissement;
        //         organizationData.codeCommuneEtablissement = siretResponse.etablissement?.adresseEtablissement?.codeCommuneEtablissement;
        //         organizationData.codePostalEtablissement = siretResponse.etablissement?.adresseEtablissement?.codePostalEtablissement;

        //         // console.log(organizationData)
        //         // if (isAssociation && !organizationData?.identifiantCanal) {
        //         //     setErrSiretAsso("Le RNA fourni n'appartient pas à une association.");
        //         //     setRequestInProcess(false);
        //         //     return;
        //         // } else {
        //         //     setErrSiretAsso(null);
        //         // }


        //     } else if (actionResult.type === fetchSiretData.fulfilled.type) {

        //         const siretResponse = actionResult.payload;

        //         organizationData.denomination = siretResponse.etablissement?.uniteLegale?.denominationUniteLegale || '';
        //         organizationData.identifiantCanal = siretResponse.etablissement?.uniteLegale?.identifiantAssociationUniteLegale || siretResponse.etablissement?.uniteLegale?.economieSocialeSolidaireUniteLegale;
        //         organizationData.libelleEtablissement = siretResponse.etablissement?.adresseEtablissement?.libelleCommuneEtablissement;
        //         organizationData.codeCommuneEtablissement = siretResponse.etablissement?.adresseEtablissement?.codeCommuneEtablissement;
        //         organizationData.codePostalEtablissement = siretResponse.etablissement?.adresseEtablissement?.codePostalEtablissement;
        //     }

        //     else {
        //         Swal.fire({
        //             icon: 'error',
        //             title: 'Erreur',
        //             text: `Une erreur s'est produite lors de la mise à jour de l\'ajout du SIRET : ${fetchRNAData.fulfilled.error}`,
        //             confirmButtonColor: '#2F4F4F',
        //             confirmButtonText: 'OK',
        //             showClass: {
        //                 popup: 'animate__animated animate__fadeIn'
        //             },
        //             hideClass: {
        //                 popup: 'animate__animated animate__fadeOut'
        //             }
        //         });
        //     }
        // }

        if (userType === 'visitor' && isAssociation && (associationRna || associationSiret)) {
            // dispatch(resetSiretState());
            let actionResult;
            if (associationRna) {
                actionResult = await dispatch(fetchRNAData(associationRna));
            } else {
                actionResult = await dispatch(fetchSiretData(associationSiret));
            }
            if (actionResult.type === fetchRNAData.fulfilled.type) {
                const siretResponse = actionResult.payload;
                // console.log(siretResponse)

                // const denomination = action.payload.identite?.nom || '';
                // const labelEtablissement = action.payload.adresseEtablissement?.libelleCommuneEtablissement;
                // const codeCommuneEtablissement = action.payload.adresseEtablissement?.libelleCommuneEtablissement;
                // const codePostalEtablissement = action.payload.adresseEtablissement?.libelleCommuneEtablissement;

                organizationData.denomination = siretResponse.identite?.nom || '';
                organizationData.identifiantCanal = siretResponse.etablissement?.uniteLegale?.identifiantAssociationUniteLegale || siretResponse.etablissement?.uniteLegale?.economieSocialeSolidaireUniteLegale;
                organizationData.libelleEtablissement = siretResponse.identite?.nom
                organizationData.codeCommuneEtablissement = siretResponse.coordonnees?.adresse_siege?.code_insee;
                organizationData.codePostalEtablissement = siretResponse.coordonnees?.adresse_siege?.cp;

                // console.log(organizationData)
                // if (isAssociation && !organizationData?.identifiantCanal) {
                //     setErrSiretAsso("Le RNA fourni n'appartient pas à une association.");
                //     setRequestInProcess(false);
                //     return;
                // } else {
                //     setErrSiretAsso(null);
                // }


            } else if (fetchSiretData.fulfilled.type) {
                const siretResponse = actionResult.payload;
                // console.log(siretResponse)
                organizationData.denomination = siretResponse.etablissement?.uniteLegale?.denominationUniteLegale || '';
                organizationData.identifiantCanal = siretResponse.etablissement?.uniteLegale?.identifiantAssociationUniteLegale || siretResponse.etablissement?.uniteLegale?.economieSocialeSolidaireUniteLegale;
                organizationData.libelleEtablissement = siretResponse.etablissement?.adresseEtablissement?.libelleCommuneEtablissement;
                organizationData.codeCommuneEtablissement = siretResponse.etablissement?.adresseEtablissement?.codeCommuneEtablissement;
                organizationData.codePostalEtablissement = siretResponse.etablissement?.adresseEtablissement?.codePostalEtablissement;

                // console.log(organizationData)
                if (isAssociation && !organizationData?.identifiantCanal) {
                    setErrSiretAsso("Le SIRET fourni n'appartient pas à une association.");
                    setRequestInProcess(false);
                    return;
                } else {
                    setErrSiretAsso(null);
                }

            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Erreur',
                    text: `Une erreur s'est produite lors de la mise à jour de l\'ajout du RNA : ${fetchRNAData.fulfilled.error}`,
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK',
                    showClass: {
                        popup: 'animate__animated animate__fadeIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOut'
                    }
                });
            }
        }


        const userData = {
            name,
            surname,
            email,
            userType: isAssociation ? 'association' : userType,
            siret: userType !== 'visitor' ? siret : null,
            siretAsso: isAssociation ? associationSiret : null,
            rnaAsso: isAssociation ? associationRna : null,
            isMairie: (userType === 'mairie' && !isAssociation) ? true : false,
            mairieName: userType === 'mairie' ? siretData.denomination : null,
            isAssociation: isAssociation,
            associationName: isAssociation ? organizationData.denomination : null,
            postalCode: userType === 'mairie' ? siretData.codePostalEtablissement : null,
            communeCode: userType === 'mairie' ? siretData.codeCommuneEtablissement : null,
            communeLibelle: userType === 'mairie' ? siretData.libelleEtablissement : null,
            postalCodeAsso: isAssociation ? organizationData.codePostalEtablissement : null,
            communeCodeAsso: isAssociation ? organizationData.codeCommuneEtablissement : null,
            communeLibelleAsso: isAssociation ? organizationData.libelleEtablissement : null,
            newsletterConsent: newsletterConsent,
            associationReachable: isAssociation ? true : null,
            isLinkedToAnAssociation: isAssociation ? true : null,
            linkedAssociationIds: isAssociation ? (associationSiret ? [associationSiret] : (associationRna ? [associationRna] : [])) : [],
            associationId: isAssociation ? (associationSiret || associationRna || null) : null
        }

        // console.log('siretData :', siretData)
        // console.log("organizationData : ", organizationData)
        // console.log("user data : ", userData);
        // return;

        if (errMail.email || errName.str || errSurname.str || errCommunity.str || errAssociation.str || errPassword.password || errPhoneNumber.phoneNumber || errSiret || errSiretAsso) {
            setRequestInProcess(false);
            Swal.fire({
                icon: 'error',
                title: `${t('modal.error')}`,
                text: `${t('modal.errorWhenCheckingForm')}`,
                confirmButtonColor: '#2F4F4F',
                confirmButtonText: 'OK',
                showClass: {
                    popup: 'animate__animated animate__fadeIn'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOut'
                }
            })
            return;
        }

        // if (!selectedProduct && userType === 'mairie') {
        //     // alert("Erreur de validation du formulaire. Veuillez corriger les erreurs avant de soumettre à nouveau.");
        //     setRequestInProcess(false);
        //     Swal.fire({
        //         icon: 'error',
        //         title: `${t('modal.error')}`,
        //         text: `${t('modal.pleaseSelectAProduct')}`,
        //         confirmButtonColor: '#2F4F4F',
        //         confirmButtonText: 'OK',
        //         showClass: {
        //             popup: 'animate__animated animate__fadeIn'
        //         },
        //         hideClass: {
        //             popup: 'animate__animated animate__fadeOut'
        //         }
        //     })
        //     return;
        // }


        try {
            const actionResult = await dispatch(registerUser({ email, password, name, surname, ...userData }));

            // Si l'action réussi, redirigez vers la page d'accueil
            if (registerUser.fulfilled.match(actionResult)) {

                /* **************** */  /* EARLYACCESS */
                // let uid = actionResult.payload.uid;
                // let productId = 2;
                // if (actionResult.meta.arg.userType === 'mairie') {
                //     dispatch(addLicence({ userId: uid, licenceId: '', productType: productId, earlyAccess: true, tryMonth: false, standardRegistration: false }));
                // } else if (actionResult.meta.arg.userType === 'association'){
                //     let productId = 5;
                //     dispatch(addLicence({ userId: uid, licenceId: '', productType: productId, earlyAccess: true, tryMonth: false, standardRegistration: false }));
                // }
                /* ******************** */

                /* **************** */  /* MAIRIE ASSOCIATION STANDARD ACCESS */
                let uid = actionResult.payload.uid;
                let productId = 1;
                if (actionResult.meta.arg.userType === 'mairie' || actionResult.meta.arg.userType === 'association') {
                    dispatch(addLicence({ userId: uid, licenceId: '', productType: productId, earlyAccess: false, tryMonth: false, standardRegistration: true }));
                }
                /* ******************** */

                /* **************** */  /* TRY MONTH */

                // let uid = actionResult.payload.uid;
                // let productId = selectedProduct.id;
                // if (actionResult.meta.arg.userType === 'mairie') {
                //     dispatch(addLicence({ userId: uid, licenceId: '', productType: productId, earlyAccess: false, tryMonth: true }));
                // }
                /* ******************** */
                setRequestInProcess(true);
                navigate('/');
                Swal.fire({
                    icon: 'success',
                    title: `${t('modal.registrationOk')}`,
                    text: `${t('modal.registrationText')}`,
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK',
                    showClass: {
                        popup: 'animate__animated animate__fadeIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOut'
                    }
                })
            } else if (registerUser.rejected.match(actionResult)) {
                setError(getErrorMsg(actionResult.payload.code));
            }
        } catch (error) {
            setError(getErrorMsg(error.code));
            console.log({ code: error.code, message: error.message });
        }
    };

    return (
        <div className='auth-page'>

            <Helmet>
                <meta name="description" content="Kappze est le premier outil dans la gestion des chats errants à destination des mairies et associations. Les chats libres, vraiment libres." />
            </Helmet>
            <div className="auth-section1">
                <div className="background-overlay"></div>
                <h1>Kappze</h1>
                <h2>Le 1er outil de gestion des chats errants</h2>
            </div>

            <div className="auth-section2">
                <h1 className='auth-section2-title' style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>{t('title')}</h1>
                <div>
                    <img src={logocat} alt="Logo" style={{ maxHeight: '100px', backgroundColor: 'none' }} />
                </div>
                <form onSubmit={handleSubmit} className='authForm  password-input'>
                    <h2 style={{ color: 'white', alignSelf: 'center' }}>{t('registration')}</h2>

                    <div className='auth-input'>
                        <label className="label" style={{ alignSelf: 'center' }}>Sélectionner votre situation</label>
                        <div className="radio-container" style={{ flexDirection: 'space-between', width: '100%' }}>
                            <label className={`radio-text ${userType === 'visitor' && 'selected-radio-text'}`}>
                                <input type="radio" id="visitor" name="userType" value="visitor" checked={userType === 'visitor'} onChange={(e) => setUserType(e.target.value)} />
                                {t('visitor')} *
                            </label>
                            <p>- ou -</p>
                            <label className={`radio-text ${userType === 'mairie' && 'selected-radio-text'}`}>
                                <input type="radio" id="mairie" name="userType" value="mairie" checked={userType === 'mairie'} onChange={(e) => setUserType(e.target.value)} />
                                {t('municipality')} **
                            </label>
                        </div>
                        <p style={{ fontSize: '12px' }}>* ou association.</p>
                        <p style={{ fontSize: '12px' }}>** ou structure intercommunale, ou responsable attitré.</p>

                    </div>
                    {/* {userType === 'mairie' && (
                        <div className='auth-input'>
                            <label htmlFor="nomCommune">{t('nameCommunity')}</label>
                            <input required type="nom" id="nomCommune" value={communityName} onChange={handleCommunityNameChange} />
                            {errCommunity.str && <span className="error-message">{errCommunity.str}</span>}
                        </div>
                    )} */}
                    {userType === 'mairie' && (
                        <div className='auth-input'>
                            <label htmlFor="siretCommune">SIRET</label>
                            <input required id="siretCommune" onInput={handleSiretChange} />
                            {errSiret && <span className="error-message">{errSiret}</span>}
                        </div>
                    )}
                    <div className='auth-input'>
                        <label htmlFor="nom">{t('name')}</label>
                        <input required type="nom" id="nom" value={name} onChange={handleNameChange} />
                        {errName.str && <span className="error-message">{errName.str}</span>}
                    </div>
                    <div className='auth-input'>
                        <label htmlFor="prenom">{t('surname')}</label>
                        <input required type="prenom" id="prenom" value={surname} onChange={handleSurnameChange} />
                        {errSurname.str && <span className="error-message" >{errSurname.str}</span>}
                    </div>
                    <div className='auth-input'>
                        <label htmlFor="email">{t('email')} </label>
                        <input required id="email" value={email} onChange={handleEmailChange} />
                        {errMail.email && <span className="error-message">{errMail.email}</span>}
                        <p style={{ fontSize: "12px" }}>Attention, les emails finissant par "@free.fr" uniquement peuvent ne pas recevoir de mails. La résolution est en cours.</p>
                        {/* {errMail.email && <span className="error-message">{errMail.email}</span>} */}
                    </div>
                    <div className='auth-input'>
                        <label htmlFor="phone">{t('phoneNumber')}</label>
                        <input type="phone" id="phone" value={phone} onChange={handlePhoneChange} />
                        {errPhoneNumber.phoneNumber && <p className="error-message" >{errPhoneNumber.phoneNumber}</p>}
                    </div>
                    <div className='auth-input'>
                        <label htmlFor="password">{t('password')} (8 caractères min.)</label>
                        <input required type="password" id="password" value={password} onChange={handlePasswordChange} minLength="8" />
                        {errPassword.password && <p className="error-message">{errPassword.password}</p>}
                    </div>
                    {errorMessage && <p className="error-message">{errorMessage}</p>}

                    {userType === 'visitor' && (
                        <>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem', rowGap: '1rem', background: '#122', paddingTop: '15px', paddingBottom: '15px' }}>
                                <label htmlFor="appartenance">Etes-vous une association ?</label>
                                <label className="switch">
                                    <input type="checkbox" checked={isAssociation} onChange={(e) => setIsAssociation(e.target.checked)} />
                                    <span className="slider round register-slider"></span>
                                </label>

                                {(isAssociation && (<div className="radio-container" style={{ flexDirection: 'space-between', width: '100%', marginBottom: 0 }}>
                                    <label className={`radio-text ${assoIdType === 'rna' && 'selected-radio-text'}`}>
                                        <input type="radio" id="visitor" name="userType" value="rna" checked={assoIdType === 'rna'} onChange={(e) => setAssoIdType(e.target.value)} />
                                        RNA
                                    </label>
                                    <p>- ou -</p>
                                    <label className={`radio-text ${assoIdType === 'siret' && 'selected-radio-text'}`}>
                                        <input type="radio" id="mairie" name="userType" value="siret" checked={assoIdType === 'siret'} onChange={(e) => setAssoIdType(e.target.value)} />
                                        SIRET
                                    </label>
                                </div>))}


                            </div>

                            {(isAssociation && assoIdType === 'rna') ? (<div className='auth-input'>
                                <label htmlFor="siretAssociation">RNA Association</label>
                                <input required id="siretAssociation" onInput={handleAssociationRnaChange} />
                                {errSiretAsso && <span className="error-message">{errSiretAsso}</span>}
                            </div>) : (isAssociation && assoIdType === 'siret') ? (<div className='auth-input'>
                                <label htmlFor="siretAssociation">Siret Association</label>
                                <input required id="siretAssociation" onInput={handleAssociationSiretChange} />
                                {errSiretAsso && <span className="error-message">{errSiretAsso}</span>}
                            </div>) : (null)}
                        </>

                    )}

                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem', rowGap: '1rem', paddingTop: '15px', paddingBottom: '15px' }}>
                        <label htmlFor="newsletterConsent" style={{ fontSize: '0.8rem' }}>Je souhaite recevoir la newsletter et les offres promotionnelles.</label>
                        <label className="switch">
                            <input type="checkbox" id="newsletterConsent" checked={newsletterConsent} onChange={(e) => setNewsletterConsent(e.target.checked)} />
                            <span className="slider round register-slider"></span>
                        </label>
                    </div>


                    {/* )} */}


                    {/* </div> */}
                    <button type="submit" className="register-button">
                        {t('register')} <img style={{ widh: '25px' }} src={iconpaw} alt="Icon Paw" className="button-icon" /></button>
                </form>
                <div>
                    {requestInProcess && <div className="loaderPayment"></div>}
                </div>

                <p style={{ paddingBottom: '2rem' }}>
                    {t('alreadyAnAccount')}{" "}
                    <NavLink to="/login">
                        <span style={{ marginRight: '16px' }}><b>{t('logIn')}</b></span>
                    </NavLink>
                </p>
            </div>
        </div>
    );
};

export default RegistrationForm;

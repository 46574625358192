import './App.css';
import './MidDevices.css';
import './SmallDevices.css';
import { Provider, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor  } from './store/store';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';

import Footer from './components/general/Footer';
import Header from './components/general/Header';
import ProtectedRoute from './routing/ProtectedRoute';
import LoginPage from './pages/loginPage';
import RegistrationPage from './pages/registrationPage'
import CreateCanal from './pages/Canal/createCanal'
import ListingCanal from './pages/User/listingCanal.jsx';
import CanalDetails from './pages/Canal/profileCanal';
import JoinCanal from './pages/Canal/joinCanal';
import SettingsCanal from './pages/Canal/settingsCanal';
import ProfileCity from './pages/Cities/profileCity.jsx';
import ProfileSubSector from './pages/Cities/profileSubSector.jsx';
import Settings from './pages/settings';
import ForgotPasswordForm from './components/user/ForgotPasswordForm';
import CreateCity from './pages/Cities/createCity';
import CreateAnimal from './pages/Animals/createAnimal.jsx';
import ProfileAnimal from './pages/Animals/profileAnimal';
import UsefulLinks from './pages/usefulLinks';
import SubscribePage from './pages/User/subscribePage';
import Invoices from './pages/Invoices';
import Documentation from './pages/documentation';
import CGVDocument from './pages/General/CGV';
import MentionsLegales from './pages/General/MentionsLegales';
import SignalAnimal from './pages/Animals/signalAnimal.jsx';
import Signalements from './pages/Canal/signalements.jsx';
import Statistics from './pages/Canal/Statistics.jsx';
import EmailVerified from './pages/User/validateEmail.jsx';
import AssociationDetail from './pages/Association/profileAssociation.jsx';
import ListingAssociations from './pages/Association/listingAssociations.jsx';
import ErrorBoundaryWrapper from './components/general/ErrorBoundaryWrapper.jsx';
import Map from './pages/Maps/maps.jsx';
import MapComponent from './pages/Maps/initialMap.jsx';




if (window.location.hostname !== "localhost") {
  ReactGA.initialize('G-T9X1RWTTJ1', { debug: true });

}





const PageViewTracker = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send(location.pathname + location.search);
  }, [location]);

  return null;
};

const App = () => {
  // const isProduction = process.env.NODE_ENV === 'production';
  const isProduction = true;
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>

        <BrowserRouter>
        <ErrorBoundaryWrapper isProduction={isProduction}>
        <PageViewTracker />
          <Header />
          <Routes>
            <Route element={<ProtectedRoute />} >
              <Route path="/settings/:userId" element={<Settings />} />
              <Route path="/" element={<ListingCanal />} />
              <Route path="/links" element={<UsefulLinks />} />
              <Route path="/checkout" element={<SubscribePage />} />
              <Route path="/create-asso" element={<CreateCanal />} />
              <Route path="/join-asso" element={<JoinCanal />} />
              <Route path="/create-city/:canalId" element={<CreateCity />} />
              <Route path="/create-animal/:canalId" element={<CreateAnimal />} />
              <Route path="/signal-animal/:canalId" element={<SignalAnimal />} />

              <Route path="/user-profile" element={<ListingCanal />} />
              <Route path="/canal/:id" element={<CanalDetails />} />
              <Route path="/association/:id" element={<AssociationDetail />} />
              <Route path="/associations/" element={<ListingAssociations />} />
              <Route path="/canal/:id/settings" element={<SettingsCanal />} />
              <Route path="/canal/:id/signalements" element={<Signalements />} />
              <Route path="/canal/:linkedCanalId/:linkedCitySectorId" element={<ProfileCity />} />
              <Route path="/canal/:linkedCanalId/:linkedCitySectorId/:linkedSubSectorId" element={<ProfileSubSector />} />
              <Route path="/invoices/" element={<Invoices />} />
              <Route path="/statistics/:id/" element={<Statistics />} />
              <Route path="/validate-email" element= {<EmailVerified />} />
            </Route>
            <Route path="/animal/:id" element={<ProfileAnimal />} />
            <Route path="/registration" element={<RegistrationPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/password" element={<ForgotPasswordForm />} />
            <Route path="/cgv" element={<CGVDocument />} />
            <Route path="/mentionslegales" element={<MentionsLegales />} />
            <Route path="/documentation" element={<Documentation />} />
            <Route path="/map" element={<Map />} />
          </Routes>

          <Footer />
</ErrorBoundaryWrapper>
        </BrowserRouter>
      </PersistGate>
    </Provider>
    </>
  );
};
export default App;

import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useSelector, useDispatch } from 'react-redux';
import IconPhone from '../../assets/call-outline.svg';
import IconMail from '../../assets/mail-outline.svg';
import IconLocalisation from '../../assets/location-outline.svg';
import IconPaw from '../../assets/paw-outline.svg';
import IconPawWhite from '../../assets/paw-outline-white.svg';
import IconPeople from '../../assets/people-outline.svg';
import IconPeopleWhite from '../../assets/people-outline-white.svg';
import { fetchAnimalsByCanal } from "../../features/animals/animalSlice";
import { fetchCanalUsers } from '../../features/canals/canalUsersSlice';
import { useTranslation } from 'react-i18next';
import BannerPlaceHolder from '../../assets/cat-not-found.png'

const CanalCard = ({ canal }) => {
    const [localAnimals, setLocalAnimals] = useState([]);
    const [localUserCanal, setLocalUsersCanal] = useState([]);
    const { uid } = useSelector((state) => state.auth);
    const { isAuthenticated, isAssociation, isLinkedToAnAssociation, linkedAssociationIds } = useSelector((state) => state.auth);
    const isAdmin = canal?.role && canal.role.find((item) => item.uid === uid)?.isAdmin;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    // console.log(canal)

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchAnimalsByCanal(canal?.id)).then((result) => {
                setLocalAnimals(result.payload);
            });
            dispatch(fetchCanalUsers(canal?.id)).then((result) => {
                setLocalUsersCanal(result.payload)
            })
        }
    }, [canal, dispatch, canal?.id, isAuthenticated]);


    return (
        <>
            <NavLink className={canal.isCanalAssociation ? 'card-canal card-canalAssociation' : 'card-canal'} to={`/canal/${canal?.id}`}>

                {/* <div className="card-canal_img-title">
                    {canal?.name ? (<h2>{canal?.name}</h2>) : (<h2>{canal?.name}</h2>)}
                    {canal?.isCanalAssociation ? (<p>Appartient à une association</p>) : null}
                    {canal?.image?.url ? (
                        <LazyLoadImage effect="none" src={canal?.image.url} style={{ objectFit: 'contain' }} alt={`Image de ${canal?.name}`} />
                    ) : (

                        <LazyLoadImage effect="none" src={BannerPlaceHolder} style={{ objectFit: 'cover', opacity: '0.8' }} alt={`Image de ${canal?.name}`} />


                    )}
                    {isAdmin === true ? (
                        <div className="pastille_role pastille_role-admin">
                            <span className="pastille_role-admin-text">{t('administrator')}</span>
                        </div>
                    ) : ((isContributorInAssociation )) ? (    <div className="pastille_role pastille_role-contributor">
                            <span className="pastille_role-editor-text">Contributeur</span>
                        </div>): (
                        <div className="pastille_role pastille_role-editor">
                            <span className="pastille_role-editor-text">Visiteur</span>
                        </div>

                    )}
                </div> */}

                <div className="card-canal_img-title">
                    {canal?.name ? (<h2>{canal?.name}</h2>) : (<h2>{canal?.name}</h2>)}
                    {canal?.isCanalAssociation ? (<p>Appartient à une association</p>) : null}
                    {canal?.image?.url ? (
                        <LazyLoadImage effect="none" src={canal?.image.url} style={{ objectFit: 'contain' }} alt={`Image de ${canal?.name}`} />
                    ) : (

                        <LazyLoadImage effect="none" src={BannerPlaceHolder} style={{ objectFit: 'cover', opacity: '0.8' }} alt={`Image de ${canal?.name}`} />


                    )}
                    {isAdmin === true ? (
                        <div className="pastille_role pastille_role-admin">
                            <span className="pastille_role-admin-text">{t('administrator')}</span>
                        </div>
                    ) :  (
                        <div className="pastille_role pastille_role-editor">
                            <span className="pastille_role-editor-text">Particulier</span>
                        </div>

                    )}
                </div>

                <div className="img-informations-general">
                    <span style={{ display: 'flex', alignItems: 'center' }}><img src={canal?.isCanalAssociation ? IconPawWhite : IconPaw} className="button-icon-cards" /> <p>{localAnimals?.length}</p></span>
                    <span style={{ display: 'flex', alignItems: 'center' }}><img src={canal?.isCanalAssociation ? IconPeopleWhite : IconPeople} className="button-icon-cards" /> <p>{localUserCanal?.length}</p></span>
                </div>
                <div className="img-informations">


                    <div className="img-informations-icon">

                        <span style={{ display: 'flex', alignItems: 'center' }}><img src={IconLocalisation} className="button-icon-cards button-icon-cards-additionnal" /> <p>{canal?.city}, {canal?.postalCode}</p></span>
                        <span style={{ display: 'flex', alignItems: 'center' }}><img src={IconMail} className="button-icon-cards button-icon-cards-additionnal" /> <p>{canal?.email}</p></span>
                        <span style={{ display: 'flex', alignItems: 'center' }}><img src={IconPhone} className="button-icon-cards button-icon-cards-additionnal" /> <p>{canal?.phoneNumber}</p></span>
                    </div>
                </div>

            </NavLink>
        </>
    );
}

export default CanalCard;

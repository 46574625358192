import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Swal from 'sweetalert2';
import { addLicence } from "../features/licences/licenceSlice";
import axios from 'axios';
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import { fetchUserData, setLicenceStatus, setSubscriptionId } from "../features/user/userSlice";
import LoadingPage from "../components/general/LoadingPage";
import logopremium from '../assets/logos/kappze_logo_circle_noir_gold.png';
import logostandard from '../assets/logos/kappze_logo_circle_noir_vert.png';
import logoupgrade from '../assets/logos/kappze_logo_circle_noir_red.png';
import { useTranslation } from 'react-i18next';
import CatNotFound from '../assets/cat-not-found.png'

export const CheckoutForm = () => {
    const { t } = useTranslation();
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const [licenceError, setLicenceError] = useState(null);
    const [acceptTerms, setAcceptTerms] = useState(null);
    const [cgvError, setCgvError] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [oldBillingDetails, setOldBillingDetails] = useState(null);
    const navigate = useNavigate();
    const [paymentInProcess, setPaymentInProcess] = useState(null);
    const [billingInterval, setBillingInterval] = useState('monthly');

            // Function will execute on click of button 
            const downloadCGV = () => {
                // using Java Script method to get PDF file 
                fetch('CGV.pdf', { method: 'GET' }).then(response => {
                    response.blob().then(blob => {
                        // Creating new object of PDF file 
                        const fileURL = window.URL.createObjectURL(blob);
                        // Setting various property values 
                        let alink = document.createElement('a');
                        alink.href = fileURL;
                        alink.download = 'CGV.pdf';
                        alink.click();
                    })
                })
            }

    const handleToggle = () => {
        setBillingInterval(prevInterval => prevInterval === 'monthly' ? 'yearly' : 'monthly');
    };


    const [billingDetails, setBillingDetails] = useState({
        name: '',
        email: '',
        address: {
            line1: '',
            city: '',
            postal_code: '',
            country: ''
        }
    });

    const products = [
        {
            id: 2,
            name: 'Licence annuelle 100 animaux',
            priceId: 'price_1PsZ95Ku63J8L2mL3I2bLA8v', // ID Stripe pour l'abonnement annuel prod
            roundedPrice: 159.99,
            image: logopremium,
            isSubscription: true, // Indique que c'est un abonnement
            billingInterval: 'yearly',
            isSelectable: true, // Indique que le produit n'est pas sélectionnable
        },
        {
            id: 3,
            name: 'Licence annuelle 500 animaux',
            priceId: 'price_1PsZ91Ku63J8L2mLDIrpzUhY', // ID Stripe pour l'abonnement annuel prod
            roundedPrice: 499.99,
            image: logopremium,
            isSubscription: true,
            billingInterval: 'yearly',
            isSelectable: true, // Indique que le produit n'est pas sélectionnable
        },
        {
            id: 4,
            name: 'Licence mensuelle 100 animaux',
            priceId: 'price_1PsZ98Ku63J8L2mLFCjAUfj3', // ID Stripe pour l'abonnement mensuel prod
            roundedPrice: 15.99,
            image: logostandard,
            isSubscription: true,
            billingInterval: 'monthly',
            isSelectable: true, // Indique que le produit n'est pas sélectionnable
        },
        {
            id: 5,
            name: 'Licence mensuelle 500 animaux',
            priceId: 'price_1PsZ97Ku63J8L2mL0tTqqVER', // ID Stripe pour l'abonnement mensuel prod
            roundedPrice: 49.99,
            image: logostandard,
            isSubscription: true,
            billingInterval: 'monthly',
            isSelectable: true, // Indique que le produit n'est pas sélectionnable
        },
        {
            id: 6,
            name: 'Licence illimitée disponible sur devis',
            priceId: null, // Pas d'ID Stripe car non sélectionnable
            roundedPrice: null, // Pas de prix affiché
            image: logopremium,
            isSubscription: false,
            billingInterval: null,
            isSelectable: false, // Indique que le produit n'est pas sélectionnable
        }
        
    ];


    /* TEST */
    // const productType = 1;

    // const { uid, status, userType, licenceStatus, productType } = useSelector((state) => state.auth);
    const { uid, status, userType, licenceStatus } = useSelector((state) => state.auth);
    const currentLicence = useSelector((state) => state.auth.licenceNumber);
    const isLicenceActive = useSelector((state) => state.auth.licenceStatus);

    useEffect(() => {
        const fetchBillingInfo = async () => {
            const db = getFirestore();
            const billingInfoRef = doc(db, "billingInfo", uid);
            const billingInfoDoc = await getDoc(billingInfoRef);

            if (billingInfoDoc.exists()) {

                setOldBillingDetails(billingInfoDoc.data());
                setBillingDetails(billingInfoDoc.data());
            } else {
                console.log('error')
            }
        };

        fetchBillingInfo();
    }, [uid]);


    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!acceptTerms) {
            setCgvError(`${t('modal.youNeedToAcceptConditions')}`);
            return;
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement)
        });
        if (!error) {
            const { id } = paymentMethod;

            const getDifferences = () => {

                let differences = {};
                if (!oldBillingDetails) {
                    return differences
                }
                for (let key in billingDetails) {
                    if (typeof billingDetails[key] === 'object') {
                        for (let subKey in billingDetails[key]) {
                            if (oldBillingDetails[key][subKey] !== billingDetails[key][subKey]) {
                                differences[subKey] = billingDetails[key][subKey];
                            }
                        }
                    } else {
                        if (oldBillingDetails[key] !== billingDetails[key]) {

                            differences[key] = billingDetails[key];
                        }
                    }
                }
                return differences;
            };

            const keyMapping = {
                name: "Nom",
                email: "Email",
                "line1": "Adresse",
                "citySector": "Ville",
                "postal_code": "Code postal",
                "country": "Pays"
            };

            const formatDifferences = (differences, currentBilling) => {
                let formattedString = "";

                for (let key in differences) {
                    const friendlyKey = keyMapping[key] || key; // Utilisez la clé traduite si elle existe, sinon la clé originale

                    if (currentBilling[key] && differences[key]) {
                        formattedString += `${friendlyKey}: ${currentBilling[key]} -> ${differences[key]}\n`;
                    } else if (!currentBilling[key] && differences[key]) {
                        formattedString += `${friendlyKey} :  ${differences[key]}\n`;
                    }
                }

                return formattedString;
            };

            const differences = getDifferences();
            if (Object.keys(differences).length > 0) {

                Swal.fire({
                    title: `${t('modal.confirmation')}`,
                    text: `${t('modal.theseBillingInformationsWillBeChanged')} \n\n" + formattedDifferences + "\n\n ${t('modal.doYouWantToContinue')}`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#2F4F4F',
                    cancelButtonColor: '#d33',
                    confirmButtonText: `${t('modal.confirm')}`,
                    cancelButtonText: `${t('modal.cancel')}`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        performPayment(id, billingDetails);
                    }
                });
            } else {
                await performPayment(id, billingDetails);
            }
        } else {
            console.log(error.message);
            console.error("Stripe error:", error.message);
        }
    };

    const performPayment = async (id, billingDetails) => {
        let customerId;
        setPaymentInProcess(true);

        // Vérifiez si le client existe
        const findCustomerResponse = await axios.get("https://us-central1-kappze.cloudfunctions.net/stripeapi/stripe/find-customer", {
            params: {
                email: billingDetails.email,
                name: billingDetails.name
            }
        });

        if (findCustomerResponse.data.exists) {
            customerId = findCustomerResponse.data.customerId;
        } else {
            // Si le client n'existe pas, créez-en un nouveau
            const customerResponse = await axios.post("https://us-central1-kappze.cloudfunctions.net/stripeapi/stripe/create-customer", {
                name: billingDetails.name,
                email: billingDetails.email,
                address: {
                    line1: billingDetails.address.line1,
                    citySector: billingDetails.address.citySector,
                    postal_code: billingDetails.address.postal_code,
                    country: billingDetails.address.country
                },
            });

            if (customerResponse.data.success) {
                customerId = customerResponse.data.customerId;
                // Stockez le customerId dans Firebase
                const db = getFirestore();
                const userRef = doc(db, "users", uid);
                await setDoc(userRef, { stripeCustomerId: customerId }, { merge: true });
            } else {
                console.log("Erreur lors de la création du client");
                setPaymentInProcess(false);
                return;
            }
        }

        // Logique pour créer un abonnement si l'utilisateur a choisi un produit avec abonnement
        let subscriptionResponse;
        if (selectedProduct && selectedProduct.isSubscription) {
            subscriptionResponse = await axios.post("https://us-central1-kappze.cloudfunctions.net/stripeapi/stripe/create-subscription", {
                customerId: customerId,
                priceId: selectedProduct.priceId, // Utilisez l'ID du prix du plan mensuel ici
                amount: selectedProduct ? selectedProduct.priceId : 0,
                id: id,
                userId: uid,
                return_url: 'https://app.kappze.com',
                receipt: billingDetails.email,
                billingDetails: billingDetails,
                description: selectedProduct ? selectedProduct.name : ''
            });

            if (!subscriptionResponse.data.success) {
                console.log("Erreur lors de la création de l'abonnement");
                setPaymentInProcess(false);
                setLicenceError(`${t('modal.anErrorOccuredDuringPaymentProcess')}`);
                return;
            }

            if (subscriptionResponse.data.success) {
                const subscriptionId = subscriptionResponse.data.subscriptionId;

                // Mettez à jour le state Redux avec le nouveau subscriptionId
                dispatch(setSubscriptionId(subscriptionId));
                dispatch(setLicenceStatus(subscriptionResponse.data.status));
            }
        }

        // Confirmez le paiement de l'abonnement (ou d'un paiement unique)
        const clientSecret = subscriptionResponse ? subscriptionResponse.data.clientSecret : null;

        if (clientSecret) {
            const confirmPayment = await stripe.confirmCardPayment(clientSecret, {
                payment_method: id,
            });

            if (confirmPayment.error || confirmPayment.paymentIntent.status !== "succeeded") {
                setPaymentInProcess(false);
                setLicenceError(`${t('modal.anErrorOccuredDuringPaymentProcess')}`);
                return;
            }
        }

        // Logique pour ajouter la licence après un paiement réussi
        const actionResult = await dispatch(addLicence({ userId: uid, licenceId: currentLicence, productType: selectedProduct?.id, upgradeProduct: selectedProduct?.upgrade, billingInterval: selectedProduct?.billingInterval }));
        if (addLicence.fulfilled.match(actionResult)) {
            Swal.fire({
                title: `${t('modal.paymentSuccessTitle')}`,
                text: `${t('modal.paymentSuccess')}`,
                icon: 'success',
                confirmButtonText: `${t('modal.backToHomePage')}`,
                confirmButtonColor: '#2F4F4F',
                showClass: {
                    popup: 'animate__animated animate__fadeIn'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOut'
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch(fetchUserData(uid))
                    navigate(-1);
                }
            });
        } else {
            setLicenceError(actionResult.payload);
        }

        setPaymentInProcess(false);

        const db = getFirestore();
        const billingInfoRef = doc(db, "billingInfo", uid);
        await setDoc(billingInfoRef, billingDetails);
    };

    if (status === 'loading') {
        return <LoadingPage isLoading={true} />;
    }

    return (
        <div className="checkout-form page-main">
            <LoadingPage isLoading={false} />

            <div className="billing-buttons">
            <button
        className={billingInterval === 'monthly' ? 'active' : ''}
        onClick={() => setBillingInterval('monthly')}
    >
        Mensuel
    </button>
    <button
        className={billingInterval === 'yearly' ? 'active' : ''}
        onClick={() => setBillingInterval('yearly')}
    >
        Annuel (-16%)
    </button>

</div>



<div className="products">
    {products
        .filter(product => product.billingInterval === billingInterval || product.isSelectable === false)
        .map(product => (
            <div key={product.id} className="product-card">
                {product.isSelectable && (
                    <input
                        type="radio"
                        id={product.id}
                        name="product"
                        value={product.id}
                        onChange={() => setSelectedProduct(product)}
                        className="product-input"
                    />
                )}
                <label htmlFor={product.id} className="product-label">
                    <img src={product.image} alt={product.name} className="product-image" />
                    <h2 className="product-title">{product.name}</h2>
                    <p className="product-description">{product.description}</p>
                    {product.roundedPrice && (
                        <p className="product-price">
                            {product.roundedPrice}€ /{billingInterval === 'monthly' ? 'mois' : 'an'}
                        </p>
                    )}
                    {billingInterval === 'yearly' && product.isSelectable && (
                        <p className="product-saving">
                            {product.id === 2 ? 'Soit 16.62% par rapport au mensuel.' : 'Soit 16.67% par rapport au mensuel.'}
                        </p>
                    )}
                    {product.isSelectable ? (
                        <span className="select-text">{t('payments.select')}</span>
                    ) : (
                        <span className="select-text">Disponible sur devis</span>
                    )}
                </label>
            </div>
        ))}
</div>




            {licenceError && <p style={{ color: 'rgb(135, 41, 41)' }}>{licenceError}</p>}
            {selectedProduct &&
                <form style={{ display: 'flex', flexDirection: 'column' }} onSubmit={handleSubmit}>
                    {selectedProduct?.price && (
                        <p style={{ color: '#2f4f4f', textAlign: 'center' }}>Prix total :  {selectedProduct.billingInterval === 'monthly' ? `${selectedProduct.roundedPrice}€ /mois` : `${selectedProduct.roundedPrice}€ /an`}</p>
                    )}

                    <p style={{ color: '#2f2f2f' }}>Facturation : </p>
                    <input
                        type="text"
                        placeholder="Nom complet"
                        value={billingDetails.name}
                        onChange={(e) => setBillingDetails(prev => ({ ...prev, name: e.target.value }))}
                    />
                    <input
                        type="email"
                        placeholder="Email"
                        value={billingDetails.email}
                        onChange={(e) => setBillingDetails(prev => ({ ...prev, email: e.target.value }))}
                    />
                    <input
                        type="text"
                        placeholder="Adresse"
                        value={billingDetails.address.line1}
                        onChange={(e) => setBillingDetails(prev => ({ ...prev, address: { ...prev.address, line1: e.target.value } }))}
                    />
                    <input
                        type="text"
                        placeholder="Ville"
                        value={billingDetails.address.city}
                        onChange={(e) => setBillingDetails(prev => ({ ...prev, address: { ...prev.address, city: e.target.value } }))}
                    />
                    <input
                        type="text"
                        placeholder="Code postal"
                        value={billingDetails.address.postal_code}
                        onChange={(e) => setBillingDetails(prev => ({ ...prev, address: { ...prev.address, postal_code: e.target.value } }))}
                    />
                    <input
                        type="text"
                        placeholder="Pays"
                        value={billingDetails.address.country}
                        onChange={(e) => setBillingDetails(prev => ({ ...prev, address: { ...prev.address, country: e.target.value } }))}
                    />

                    <div style={{ color: 'black', display: 'flex', alignItems: 'center' }}>

                        <div className={acceptTerms === false ? 'red-terms' : acceptTerms === true ? 'valid-terms' : 'classic-terms'}>J'accepte les <a style={{textDecoration: 'underline' }} className="footer-elt" onClick={downloadCGV}>Conditions Générales de Vente</a><input
                            type="checkbox"
                            checked={acceptTerms}
                            onChange={(e) => setAcceptTerms(e.target.checked)}
                            style={{ width: 'unset', marginLeft: '10px' }}
                        /></div>
                

                    </div>

                    <p style={{ color: '#2f2f2f' }}>Paiement par carte : </p>
                    <CardElement options={{
                        hidePostalCode: true,
                        style: {
                            base: {
                                fontSize: '16px',
                                color: '#32325d',
                                '::placeholder': {
                                    color: '#aab7c4'
                                }
                            },
                            invalid: {
                                color: '#fa755a',
                                iconColor: '#fa755a'
                            }
                        }
                    }}
                    />

                    <button className='checkout-button' disabled={!acceptTerms}>Payer</button>
                    {paymentInProcess && <div className="loaderPayment"></div>}

                </form>}
        </div>
    );
}

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

export const validateSiret = async (siret) => {
    const errors = {};
    const siretRegex = /^[0-9]{14}$/;
    if (!siretRegex.test(siret)) {
        errors.siret = "Le SIRET est invalide. Format désiré : 00000000000000.";
        return errors; // Retourne immédiatement les erreurs si le format SIRET est invalide
    }

    const db = getFirestore();
    const siretCommunityCollection = collection(db, "siretCommunity");
    const q = query(siretCommunityCollection, where("siret", "==", siret));

    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
        // Si la requête retourne au moins un document, cela signifie que le SIRET est déjà utilisé
        errors.siret = "Ce SIRET est déjà enregistré sur la plateforme.";
    }


    return errors;
}


export const validateSiretAsso = async (siret) => {
    const errors = {};
    const siretRegex = /^[0-9]{14}$/;
    if (!siretRegex.test(siret)) {
        errors.siret = "Le SIRET est invalide. Format désiré : 00000000000000.";
        return errors; // Retourne immédiatement les erreurs si le format SIRET est invalide
    }

    const db = getFirestore();
    const siretCommunityCollection = collection(db, "organization");
    const q = query(siretCommunityCollection, where("siret", "==", siret));

    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
        // Si la requête retourne au moins un document, cela signifie que le SIRET est déjà utilisé
        errors.siret = "Ce SIRET est déjà enregistré sur la plateforme.";
    }


    return errors;
}


export const validateRnaAsso = async (rna) => {
    const errors = {};
    const rnaRegex = /^[A-Z][0-9]{9,10}$/; // Lettre majuscule suivie de 9 à 10 chiffres

    if (!rnaRegex.test(rna)) {
        errors.rna = "Le RNA est invalide. Format désiré : une lettre majuscule suivie de 9 à 10 chiffres.";
        return errors; // Retourne immédiatement les erreurs si le format RNA est invalide
    }

    const db = getFirestore();
    const siretCommunityCollection = collection(db, "organization");
    const q = query(siretCommunityCollection, where("rna", "==", rna));

    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
        // Si la requête retourne au moins un document, cela signifie que le RNA est déjà utilisé
        errors.rna = "Ce RNA est déjà enregistré sur la plateforme.";
    }

    return errors;
}


export const fetchSiretData = createAsyncThunk(
    'siret/fetchData',
    async (siretNumber) => {
        const response = await fetch(`https://us-central1-kappze.cloudfunctions.net/getSirenData?siretNumber=${siretNumber}`);
        if (!response.ok) {
            throw new Error('Une erreur est survenue lors de la récupération des données.');
        }
        
        const data = await response.json();

        // console.log(data)
        return data;
    }
);


export const fetchRNAData = createAsyncThunk(
    'siret/fetchRNAData',
    async (rnaNumber) => {
        const response = await fetch(`https://us-central1-kappze.cloudfunctions.net/getRNAData?rnaNumber=${rnaNumber}`);
        if (!response.ok) {
            throw new Error('Une erreur est survenue lors de la récupération des données.');
        }
        
        const data = await response.json();

        // console.log(data)
        return data;
    }
);


export const fetchPostalCodeData = createAsyncThunk(
    'siret/fetchPostalCodeData',
    async (postalCode) => {
        const response = await fetch(`https://us-central1-kappze.cloudfunctions.net/getPostalCodeData?postalCode=${postalCode}`);
        
        if (!response.ok) {
            throw new Error('Une erreur est survenue lors de la récupération des données.');
        }
        
        const data = await response.json();

        console.log('DATA : ', data)

        // console.log(data)
        return data;
    }
);

export const ComparePostalCodeData = createAsyncThunk(
    'siret/ComparePostalCodeData',
    async (postalCode) => {
        const db = getFirestore();
        // Crée une requête pour trouver les canaux avec le code postal donné
        const canalQuery = query(collection(db, 'canals'), where("postalCode", "==", postalCode));
        const canalQuerySnapshot = await getDocs(canalQuery);

        

        // Transforme les résultats de la requête en un tableau d'objets
        let canaux = [];
        canalQuerySnapshot.forEach((doc) => {
            canaux.push({
                id: doc.id,
                ...doc.data()
            });
        });

        // console.log('canaux', canaux)
        // Vérifie si la liste des canaux est vide
        if (canaux.length === 0) {
            throw new Error('Aucun canal trouvé pour ce code postal.');
        }

        return canaux; // Retourne les canaux trouvés
    }
);


export const ComparePostalCodeDataAsso = createAsyncThunk(
    'siret/ComparePostalCodeDataAsso',
    async (communeCode) => {
        const db = getFirestore();
        // Crée une requête pour trouver les canaux avec le code postal donné
        const canalQuery = query(collection(db, 'users'), where("communeCode", "==", communeCode));
        const canalQuerySnapshot = await getDocs(canalQuery);

        // Transforme les résultats de la requête en un tableau d'objets
        let canaux = [];
        canalQuerySnapshot.forEach((doc) => {
            canaux.push({
                id: doc.id,
                ...doc.data()
            });
        });

        // Vérifie si la liste des canaux est vide
        if (canaux.length === 0) {
            throw new Error('Aucune association trouvé pour ce code postal.');
        }

        return canaux; // Retourne les canaux trouvés
    }
);

export const GetGeoDataByAssoCode = createAsyncThunk(
    'siret/GetGeoDataByAssoCode',
    async (postalCode) => {
        const db = getFirestore();
        // Crée une requête pour trouver les canaux avec le code postal donné
        const canalQuery = query(collection(db, 'users'), where("postalCodeAsso", "==", postalCode));
        const canalQuerySnapshot = await getDocs(canalQuery);

        // Transforme les résultats de la requête en un tableau d'objets
        let canaux = [];
        canalQuerySnapshot.forEach((doc) => {
            canaux.push({
                id: doc.id,
                ...doc.data()
            });
        });

        // Vérifie si la liste des canaux est vide
        if (canaux.length === 0) {
            throw new Error('Aucune association trouvé pour ce code postal.');
        }

        return canaux; // Retourne les canaux trouvés
    }
);

// Slice pour gérer l'état
const siretSlice = createSlice({
    name: 'siret',
    initialState: {
        data: null,
        status: 'idle',
        error: null,
        isMairie: false,
        mairieName: null,
        isAssociation: false,
        associationName: null,
        labelEtablissement: null,
        codeCommuneEtablissement: null,
        codePostalEtablissement: null
    },

    reducers: {
        resetSiretState: (state) => {
            state.data = null;
            state.status = 'idle';
            state.error = null;
            state.isMairie = false;
            state.mairieName = null;
            state.isAssociation = false;
            state.associationName = null;
            state.labelEtablissement = null;
            state.codeCommuneEtablissement = null;
            state.codePostalEtablissement = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSiretData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSiretData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
                const denomination = action.payload.etablissement?.uniteLegale?.denominationUniteLegale || '';
                const identifiantCanal = action.payload.etablissement?.uniteLegale?.identifiantAssociationUniteLegale;
                const labelEtablissement = action.payload.adresseEtablissement?.libelleCommuneEtablissement;
                const codeCommuneEtablissement = action.payload.adresseEtablissement?.codeCommuneEtablissement;
                const codePostalEtablissement = action.payload.adresseEtablissement?.codePostalEtablissement;

                // console.log('denomination : ', denomination)
                if (denomination.includes('VILLE DE') || denomination.includes('MAIRIE DE') || denomination.includes('COMMUNE')) {
                    state.isMairie = true;
                    state.mairieName = denomination;
                    state.isAssociation = false;
                    state.associationName = null;
                    state.labelEtablissement = labelEtablissement;
                    state.codeCommuneEtablissement = codeCommuneEtablissement;
                    state.codePostalEtablissement = codePostalEtablissement;
                } else if (identifiantCanal) {
                    state.isMairie = false; // Assurez-vous de réinitialiser cette valeur
                    state.mairieName = null; // Assurez-vous de réinitialiser cette valeur
                    state.isAssociation = true;
                    state.associationName = denomination;
                    state.labelEtablissement = labelEtablissement;
                    state.codeCommuneEtablissement = codeCommuneEtablissement;
                    state.codePostalEtablissement = codePostalEtablissement;
                } else {
                    state.isMairie = false;
                    state.mairieName = null;
                    state.isAssociation = false;
                    state.associationName = false;
                    state.labelEtablissement = null;
                    state.codeCommuneEtablissement = null;
                    state.codePostalEtablissement = null;
                }
            })
            .addCase(fetchSiretData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
            builder
            .addCase(fetchRNAData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchRNAData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
                const denomination = action.payload.identite?.nom || '';
                const labelEtablissement = action.payload.adresseEtablissement?.libelleCommuneEtablissement;
                const codeCommuneEtablissement = action.payload.coordonnees?.adresse_siege?.code_insee;
                const codePostalEtablissement = action.payloadcoordonnees?.adresse_siege?.cp;

                // console.log('denomination : ', denomination)
                    state.isMairie = false; // Assurez-vous de réinitialiser cette valeur
                    state.mairieName = null; // Assurez-vous de réinitialiser cette valeur
                    state.isAssociation = true;
                    state.associationName = denomination;
                    state.labelEtablissement = labelEtablissement;
                    state.codeCommuneEtablissement = codeCommuneEtablissement;
                    state.codePostalEtablissement = codePostalEtablissement;
            })
            .addCase(fetchRNAData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export default siretSlice.reducer;
export const { resetSiretState } = siretSlice.actions;
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { getFirestore, doc, setDoc, updateDoc, getDoc, collection, where, query, getDocs } from 'firebase/firestore';

// export const addLicence = createAsyncThunk(
//     'licence/addLicence',
//     async ({ userId, licenceId, productType, earlyAccess, tryMonth, standardRegistration, upgrade }, { rejectWithValue }) => {
//         try {
//             const db = getFirestore();
//             let licenceData;
//             let currentLicenceDetails;
//             if (licenceId) {
//                 const licenceDocRef = doc(db, 'licences', licenceId);
//                 const licenceDoc = await getDoc(licenceDocRef);
//                 if (licenceDoc.exists()) {
//                     currentLicenceDetails = licenceDoc.data();
//                 } else {
//                     return rejectWithValue('La licence spécifiée n\'existe pas.');
//                 }
//             }

//             if (currentLicenceDetails) {
//                 const currentExpiryDate = new Date(currentLicenceDetails.expiryDate);

//                 const newLicenceNumber = Math.random().toString(36).substr(2, 9).toUpperCase();

//                 const newDateIssued = currentExpiryDate;

//                 const newExpiryDate = new Date(newDateIssued);
//                 newExpiryDate.setFullYear(newDateIssued.getFullYear() + 1);

//                 licenceData = {
//                     licenceNumber: newLicenceNumber,
//                     userId,
//                     dateIssued: newDateIssued.toISOString(),
//                     expiryDate: newExpiryDate.toISOString(),
//                     status: "active",
//                     productType: productType
//                 };

//                 const licenceDoc = doc(db, 'licences', newLicenceNumber);
//                 await setDoc(licenceDoc, licenceData);

//                 const userDoc = doc(db, 'users', userId);
//                 await updateDoc(userDoc, { licenceNumber: newLicenceNumber, productType: productType, licenceStatus: 'active' });
//             }
//             else if (earlyAccess) {

//                 // Générer un numéro de licence unique
//                 const licenceNumber = 'kappzeEarlyAccess' + Math.random().toString(36).substr(2, 9).toUpperCase();;

//                 // Date d'émission et date d'expiration
//                 const dateIssued = new Date();
//                 const expiryDate = new Date();
//                 expiryDate.setFullYear(dateIssued.getFullYear() + 100);

//                 licenceData = {
//                     licenceNumber: licenceNumber,
//                     userId,
//                     dateIssued: dateIssued.toISOString(),
//                     expiryDate: expiryDate.toISOString(),
//                     status: "active",
//                     productType: productType
//                 };

//                 // Ajouter la licence à la collection 'licences'
//                 const licenceDoc = doc(db, 'licences', licenceNumber);
//                 await setDoc(licenceDoc, licenceData);

//                 // Mettre à jour l'utilisateur avec le numéro de licence
//                 const userDoc = doc(db, 'users', userId);
//                 await updateDoc(userDoc, { licenceNumber: licenceNumber, productType: productType, licenceStatus: 'active', isBeta: true });
//             }
//             else if (standardRegistration) {
//                 // Generate a unique license number
//                 const licenceNumber = 'standardRegistration' + Math.random().toString(36).substr(2, 9).toUpperCase();

//                 // Issue date and expiration date
//                 const dateIssued = new Date();
//                 const expiryDate = new Date(dateIssued);
//                 expiryDate.setFullYear(dateIssued.getFullYear() + 100);
//                 licenceData = {
//                     licenceNumber: licenceNumber,
//                     userId,
//                     dateIssued: dateIssued.toISOString(),
//                     expiryDate: expiryDate.toISOString(),
//                     status: "active",
//                     productType: productType
//                 };

//                 // Add the license to the 'licenses' collection
//                 const licenceDoc = doc(db, 'licences', licenceNumber);
//                 await setDoc(licenceDoc, licenceData);

//                 // Update the user with the license number
//                 const userDoc = doc(db, 'users', userId);
//                 await updateDoc(userDoc, { licenceNumber: licenceNumber, productType: productType });
//             }
//             else if (currentLicenceDetails && upgrade) {
//                 // Generate a unique license number
//                 const currentExpiryDate = new Date(currentLicenceDetails.expiryDate);
//                 const licenceNumber = 'upgrade' + Math.random().toString(36).substr(2, 9).toUpperCase();

//                 // Issue date and expiration date
//                 const dateIssued = new Date();
//                 const expiryDate = currentExpiryDate;
//                 expiryDate.setFullYear(dateIssued.getFullYear() + 100);
//                 licenceData = {
//                     licenceNumber: licenceNumber,
//                     userId,
//                     dateIssued: dateIssued.toISOString(),
//                     expiryDate: expiryDate.toISOString(),
//                     status: "active",
//                     productType: productType
//                 };

//                 // Add the license to the 'licenses' collection
//                 const licenceDoc = doc(db, 'licences', licenceNumber);
//                 await setDoc(licenceDoc, licenceData);

//                 // Update the user with the license number
//                 const userDoc = doc(db, 'users', userId);
//                 await updateDoc(userDoc, { licenceNumber: licenceNumber, productType: productType });
//             }
//             else {

//                 // Générer un numéro de licence unique
//                 const licenceNumber = Math.random().toString(36).substr(2, 9).toUpperCase();

//                 // Date d'émission et date d'expiration
//                 const dateIssued = new Date();
//                 const expiryDate = new Date();
//                 expiryDate.setFullYear(dateIssued.getFullYear() + 1);

//                 licenceData = {
//                     licenceNumber,
//                     userId,
//                     dateIssued: dateIssued.toISOString(),
//                     expiryDate: expiryDate.toISOString(),
//                     status: "active",
//                     productType: productType
//                 };

//                 // Ajouter la licence à la collection 'licences'
//                 const licenceDoc = doc(db, 'licences', licenceNumber);
//                 await setDoc(licenceDoc, licenceData);

//                 // Mettre à jour l'utilisateur avec le numéro de licence
//                 const userDoc = doc(db, 'users', userId);
//                 await updateDoc(userDoc, { licenceNumber: licenceNumber, productType: productType});
//             }

//             return licenceData;

//         } catch (error) {
//             return rejectWithValue(error.toString());
//         }
//     }
// );


export const addLicence = createAsyncThunk(
    'licence/addLicence',
    async ({ userId, licenceId, productType, earlyAccess, tryMonth, standardRegistration, upgrade, billingInterval }, { rejectWithValue }) => {
        try {
            const db = getFirestore();
            let licenceData;
            let currentLicenceDetails;

            if (licenceId) {
                const licenceDocRef = doc(db, 'licences', licenceId);
                const licenceDoc = await getDoc(licenceDocRef);
                if (licenceDoc.exists()) {
                    currentLicenceDetails = licenceDoc.data();
                } else {
                    return rejectWithValue('La licence spécifiée n\'existe pas.');
                }
            }

            // Génération du numéro de licence unique
            const generateLicenceNumber = (prefix = '') => prefix + Math.random().toString(36).substr(2, 9).toUpperCase();

            if (currentLicenceDetails) {
                const currentExpiryDate = new Date(currentLicenceDetails.expiryDate);

                const newLicenceNumber = generateLicenceNumber();

                const newDateIssued = currentExpiryDate;

                let newExpiryDate = new Date(newDateIssued);
                
                // Ajustement de l'expiration en fonction du type d'abonnement
                if (billingInterval === 'monthly') {
                    newExpiryDate.setMonth(newDateIssued.getMonth() + 1); // Ajoute 1 mois
                } else {
                    newExpiryDate.setFullYear(newDateIssued.getFullYear() + 1); // Ajoute 1 an
                }

                licenceData = {
                    licenceNumber: newLicenceNumber,
                    userId,
                    dateIssued: newDateIssued.toISOString(),
                    expiryDate: newExpiryDate.toISOString(),
                    status: "active",
                    productType: productType
                };

                const licenceDoc = doc(db, 'licences', newLicenceNumber);
                await setDoc(licenceDoc, licenceData);

                const userDoc = doc(db, 'users', userId);
                await updateDoc(userDoc, { licenceNumber: newLicenceNumber, productType: productType, licenceStatus: 'active' });
            }
            else if (earlyAccess) {
                // Gestion du mode early access (illimité)
                const licenceNumber = generateLicenceNumber('kappzeEarlyAccess');

                const dateIssued = new Date();
                const expiryDate = new Date();
                expiryDate.setFullYear(dateIssued.getFullYear() + 100); // Expiration illimitée

                licenceData = {
                    licenceNumber,
                    userId,
                    dateIssued: dateIssued.toISOString(),
                    expiryDate: expiryDate.toISOString(),
                    status: "active",
                    productType: productType
                };

                const licenceDoc = doc(db, 'licences', licenceNumber);
                await setDoc(licenceDoc, licenceData);

                const userDoc = doc(db, 'users', userId);
                await updateDoc(userDoc, { licenceNumber: licenceNumber, productType: productType, licenceStatus: 'active', isBeta: true });
            }
            else if (standardRegistration) {
                const licenceNumber = generateLicenceNumber('standardRegistration');

                const dateIssued = new Date();
                const expiryDate = new Date();
                expiryDate.setFullYear(dateIssued.getFullYear() + 100); // Expiration illimitée pour standard registration

                licenceData = {
                    licenceNumber,
                    userId,
                    dateIssued: dateIssued.toISOString(),
                    expiryDate: expiryDate.toISOString(),
                    status: "active",
                    productType: productType
                };

                const licenceDoc = doc(db, 'licences', licenceNumber);
                await setDoc(licenceDoc, licenceData);

                const userDoc = doc(db, 'users', userId);
                await updateDoc(userDoc, { licenceNumber: licenceNumber, productType: productType });
            }
            else if (currentLicenceDetails && upgrade) {
                const currentExpiryDate = new Date(currentLicenceDetails.expiryDate);
                const licenceNumber = generateLicenceNumber('upgrade');

                const dateIssued = new Date();
                const expiryDate = currentExpiryDate;
                expiryDate.setFullYear(dateIssued.getFullYear() + 100); // Gestion d'une expiration lointaine en cas de mise à niveau

                licenceData = {
                    licenceNumber,
                    userId,
                    dateIssued: dateIssued.toISOString(),
                    expiryDate: expiryDate.toISOString(),
                    status: "active",
                    productType: productType,
                    billingInterval : billingInterval
                };

                const licenceDoc = doc(db, 'licences', licenceNumber);
                await setDoc(licenceDoc, licenceData);

                const userDoc = doc(db, 'users', userId);
                await updateDoc(userDoc, { licenceNumber: licenceNumber, productType: productType });
            }
            else {
                const licenceNumber = generateLicenceNumber();

                const dateIssued = new Date();
                const expiryDate = new Date();

                // Ajustement de l'expiration en fonction du type d'abonnement
                if (billingInterval === 'monthly') {
                    expiryDate.setMonth(dateIssued.getMonth() + 1); // Expiration à 1 mois
                } else {
                    expiryDate.setFullYear(dateIssued.getFullYear() + 1); // Expiration à 1 an
                }

                licenceData = {
                    licenceNumber,
                    userId,
                    dateIssued: dateIssued.toISOString(),
                    expiryDate: expiryDate.toISOString(),
                    status: "active",
                    productType: productType,
                    billingInterval : billingInterval
                };

                const licenceDoc = doc(db, 'licences', licenceNumber);
                await setDoc(licenceDoc, licenceData);

                const userDoc = doc(db, 'users', userId);
                await updateDoc(userDoc, { licenceNumber: licenceNumber, productType: productType });
            }

            return licenceData;

        } catch (error) {
            return rejectWithValue(error.toString());
        }
    }
);


export const fetchLicenceById = createAsyncThunk(
    'licence/fetchLicenceById',
    async (licenceId, { rejectWithValue }) => {

    
        try {
            const db = getFirestore();
            const licenceDocRef = doc(db, 'licences', licenceId);
            const licenceDoc = await getDoc(licenceDocRef);

            if (licenceDoc.exists()) {
                const licenceData = licenceDoc.data();
                return licenceData;
              } else {
                return rejectWithValue('La licence spécifiée n\'existe pas.');
            }
        } catch (error) {
            return rejectWithValue(error.toString());
        }
    }
);


// export const updateLicenceStatus = createAsyncThunk(
//     'licence/updateLicenceStatus',
//     async ({ licenceId, canalsId }, { getState, rejectWithValue }) => {
//         try {
//             const db = getFirestore();
//             const licenceDocRef = doc(db, 'licences', licenceId);
//             const licenceDoc = await getDoc(licenceDocRef);

//             if (!licenceDoc.exists()) {
//                 return rejectWithValue('La licence spécifiée n\'existe pas.');
//             }

//             const licenceData = licenceDoc.data();
//             const animalsCount = await fetchAnimalsCountByCanals(canalsId, licenceData.userId);
//             // const animalsCount = 101;


//             let newStatus = licenceData.status; // Start with the current status

//             // Check the type of license and update the status accordingly
//             switch (licenceData.productType) {
//                 case 1: // New ProductType4, up to 20 animals
//                 if (animalsCount > 30) {
//                     newStatus = 'inactive';
//                 } else {
//                     newStatus = 'active';
//                 }
//                 break;
//                 case 2: // Standard license, up to 100 animals
//                     if (animalsCount >= 100) {
//                         newStatus = 'inactive';
//                     } else {
//                         newStatus = 'active';
//                     }
//                     break;
//                 case 3: // Premium license, unlimited animals
//                     newStatus = 'active'; // Always active regardless of animal count
//                     break;
//                 case 4: // Monthly license, up to 100 animals
//                     if (animalsCount >= 100) {
//                         newStatus = 'inactive';
//                     } else {
//                         newStatus = 'active';
//                     }
//                     break;
//                 case 5: // Premium license, unlimited animals
//                     newStatus = 'active'; // Always active regardless of animal count
//                     break;
//                 default:
//                     newStatus = 'active'; // Default case for other types, assuming always active
//                     break;
//             }

//             if (newStatus !== licenceData.status) {
//                 // Only update Firestore if there is a status change
//                 await setDoc(licenceDocRef, { status: newStatus }, { merge: true });
//                 licenceData.status = newStatus; // Update the status in the returned data
//             }

//             return { ...licenceData, status: newStatus };
//         } catch (error) {
//             return rejectWithValue(error.toString());
//         }
//     }
// );


export const updateLicenceStatus = createAsyncThunk(
    'licence/updateLicenceStatus',
    async ({ licenceId, canalsId }, { getState, rejectWithValue }) => {
        try {
            const db = getFirestore();
            const licenceDocRef = doc(db, 'licences', licenceId);
            const licenceDoc = await getDoc(licenceDocRef);

            if (!licenceDoc.exists()) {
                return rejectWithValue('La licence spécifiée n\'existe pas.');
            }

            const licenceData = licenceDoc.data();
            const animalsCount = await fetchAnimalsCountByCanals(canalsId, licenceData.userId);

            // Initialisation des dates si elles n'existent pas
            const currentDate = new Date();

            let dateIssued = licenceData.dateIssued ? new Date(licenceData.dateIssued) : currentDate;
            let expiryDate = licenceData.expiryDate ? new Date(licenceData.expiryDate) : new Date(currentDate);

            // Si les dates n'existent pas, les initialiser avec des valeurs par défaut
            if (!licenceData.dateIssued) {
                dateIssued = currentDate;
            }
            if (!licenceData.expiryDate) {
                if (licenceData.billingInterval === 'monthly') {
                    expiryDate.setMonth(dateIssued.getMonth() + 1); // 1 mois plus tard
                } else {
                    expiryDate.setFullYear(dateIssued.getFullYear() + 1); // 1 an plus tard
                }
            }

            let newStatus = licenceData.status; // Démarrer avec le statut actuel

            // Vérifiez le type de licence et mettez à jour le statut en conséquence
            switch (licenceData.productType) {
                case 1: // Licence de type 1, jusqu'à 30 animaux
                    newStatus = animalsCount > 30 ? 'inactive' : 'active';
                    break;
                case 2: // Licence standard, jusqu'à 100 animaux
                    newStatus = animalsCount >= 100 ? 'inactive' : 'active';
                    break;
                case 3: // Licence premium, animaux illimités
                    newStatus = 'active'; // Toujours actif
                    break;
                case 4: // Licence mensuelle, jusqu'à 100 animaux
                    newStatus = animalsCount >= 100 ? 'inactive' : 'active';
                    break;
                case 5: // Licence premium, animaux illimités
                    newStatus = 'active'; // Toujours actif
                    break;
                default:
                    newStatus = 'active'; // Cas par défaut, toujours actif
                    break;
            }

            // Si le statut a changé ou si les dates sont initialisées, mettre à jour Firestore
            if (newStatus !== licenceData.status || !licenceData.dateIssued || !licenceData.expiryDate) {
                await setDoc(licenceDocRef, {
                    status: newStatus,
                    dateIssued: dateIssued.toISOString(),
                    expiryDate: expiryDate.toISOString()
                }, { merge: true });

                licenceData.status = newStatus;
                licenceData.dateIssued = dateIssued.toISOString();
                licenceData.expiryDate = expiryDate.toISOString();
            }

            return { ...licenceData, status: newStatus };
        } catch (error) {
            return rejectWithValue(error.toString());
        }
    }
);


// Faire en sorte de compter les animaux venant d'un canal créé par le créateur de canal lui même pour éviter de
// Compter les animaux créé par des associations dans les canaux de Mairies 
// async function fetchAnimalsCountByCanals(canalsId, db) {
//     const q = query(collection(db, 'animals'), where('canalId', 'in', canalsId));
//     const querySnapshot = await getDocs(q);
//     return querySnapshot.size; // Returns the total count of animals across all canals
// }

export async function fetchAnimalsCountByCanals(canalsId, userId) {
    const db = getFirestore();  // Initialiser Firestore directement dans la fonction

    // Récupérer les canaux créés par l'utilisateur
    const canalsQuery = query(
        collection(db, 'canals'),
        where('adminId', '==', userId),
        where('id', 'in', canalsId)
    );

    const canalsSnapshot = await getDocs(canalsQuery);
    const userCreatedCanals = canalsSnapshot.docs.map(doc => doc.id);


    if (userCreatedCanals.length === 0) {
        return 0; // L'utilisateur n'a créé aucun canal dans cette sélection
    }

    // Compter les animaux uniquement dans les canaux créés par l'utilisateur
    const animalsQuery = query(
        collection(db, 'animals'),
        where('canalId', 'in', userCreatedCanals)
    );

    const animalsSnapshot = await getDocs(animalsQuery);
    return animalsSnapshot.size; // Retourne le nombre total d'animaux dans les canaux créés par l'utilisateur
}

const licenceSlice = createSlice({
    name: 'licence',
    initialState: {
        licence: null,
        status: 'idle',
        error: null
    },
    reducers: {
        licence: (state, action) => {
            state.licence = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addLicence.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(addLicence.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.licence = action.payload;
            })
            .addCase(addLicence.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchLicenceById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchLicenceById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.licence = action.payload;

                // console.log("LICENCE STATE : ", action.payload)
            })
            .addCase(fetchLicenceById.rejected, (state, action) => {
                state.status = 'failed';
                state.licence = action.payload;
                state.error = action.error.message;
            });
    }
});

export default licenceSlice.reducer;